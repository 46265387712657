import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const DdOptionsGetAction = () => (dispatch) => {
  const getSharepointData = (token) => {
    axios
      .post(
        // `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('dropdownOptions')/items`,
        `${AxiosDefaultPort}apis/sharepoint/ddOptionsGet`,{token}

        // {
        //   headers: {
        //     Authorization: "Bearer " + token,
        //   },
        // }
      )
      .then((response) => {
        let temp = [...response.data.value];
        temp.map((val) => {
          val.value = val.ID;
        });

        dispatch({
          type: "DROPDOWN_OPTIONS_GET",
          payload: temp,
        });
      });
  };
  getAccessToken(getSharepointData);
};
