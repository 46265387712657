import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import Icons from "../../constants/Icons";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Toolbar,
  CssBaseline,
  AppBar,
  IconButton,
  Badge,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Modal,
} from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import { TiHome } from "react-icons/ti";
import { MdCampaign } from "react-icons/md";
import { MdPersonSearch } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
import { RiAdminLine } from "react-icons/ri";

import { COLORS } from "../../constants/theme";
import useWindowSize from "./useWindowSize";
import MenuIcon from "@mui/icons-material/Menu";
import { EmployeeDetailsGetAction } from "../../Redux/Action/EmployeeDetailsGetAction";
import { connect, useDispatch } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import BetaCodeLogo from "../../assets/images/beta-code-logo.png";
import TrueTalentLogo from "../../assets/images/true-talent-logo.png";
import AXIOS from "../../utils/AXIOS";
import axios from "../Axios/axios";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BadgeIcon from "@mui/icons-material/Badge";
const Navbar = (props, { headText }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [adminAccess, setAdminAccess] = useState(null);
  const [viewAccess, setViewAccess] = useState(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    setMobileView(windowSize.width < 600);
  }, [windowSize]);
  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;
        console.log("ASdkjasndnasd", role);

        let tempRole = [];
        tempRole.push(role);

        setUserRoles(tempRole);

        if (role == "admin") {
          setAdminAccess(true);
          setViewAccess(true);
        }
        if (role == "view") {
          setViewAccess(false);
        }
      }
    }
  }, [props.employee_data]);

  const RoutesArray = [
    {
      label: "Home",
      icon: <HomeIcon />,
      path: "/dashboard",
      ACTIVE_KEY: "Dashboard",
      access: true,
    },
    {
      label: "Activity Status",
      icon: <AssessmentIcon />,
      path: "/activity-status",
      ACTIVE_KEY: "ACTIVITY_STATUS",
      access:
        userRoles.includes("admin") ||
        userRoles.includes("view") ||
        userRoles.includes("editDelete"),
      minWidth: 170,
    },
    {
      label: "Contact",
      icon: <PermContactCalendarIcon />,
      path: "/contactCapture",
      ACTIVE_KEY: "CONTACT_CAPTURE",
      access:
        userRoles.includes("admin") ||
        userRoles.includes("editDelete") ||
        userRoles.includes("view"),
    },
    {
      label: "Search",
      icon: <PersonSearchIcon />,
      path: "/searchPage",
      ACTIVE_KEY: "SEARCH_CONTACT",
      access: true,
    },
    {
      label: "Campaign",
      icon: <CampaignIcon />,
      path: "/sendEmail",
      ACTIVE_KEY: "CREATE_CAMPAIGN",
      access: userRoles.includes("admin") || userRoles.includes("editDelete"),
    },
    {
      label: "Admin",
      icon: <AdminPanelSettingsIcon />,
      path: "/admin",
      ACTIVE_KEY: "ADMIN",
      access: adminAccess,
    },
    {
      label: "Hierarchy",
      icon: <BadgeIcon />,
      path: "/employee-hierarchy",
      ACTIVE_KEY: "EMPLOYEE_HIERARCHY",
      access: adminAccess,
    },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    border: "none",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const SessionLogout = async () => {
    const token = window.localStorage.getItem("CMS_STOKEN");
    console.log("kjnjnsadasd", token);

    await axios
      .post(AXIOS.defaultPort + AXIOS.userLogout, {
        SESSION_TOKEN: token,
      })
      .then((response) => {
        console.log("Adaskndasnkd", response);
      })
      .catch((err) => {
        console.log("Adaskndasnkd", err);
      });
    window.localStorage.removeItem("CMS_STOKEN");
    dispatch({
      type: "AUTH_DATA",
      auth_name: "",
      auth_email_id: "",
      user_token: "",
    });
    navigate("/");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <HideOnScroll> */}
      <AppBar
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              // flexGrow: 1,
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                // backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
                padding: 1,
              }}
            >
              <img
                src={TrueTalentLogo}
                alt="betacode logo"
                onClick={() => {
                  navigate("/dashboard");
                }}
                className="img-responsive logo-img"
              />
            </Box>
            {mobileView == false && (
              <>
                {RoutesArray.map((val) => {
                  return (
                    <>
                      {val.access == true && (
                        <ListItem
                          // className="nav-bar-item"
                          disablePadding
                          sx={{
                            borderRadius: 4,
                            marginRight: 1,
                            overflow: "hidden",
                            minWidth: val?.minWidth,
                            color:
                              val.ACTIVE_KEY == props.headText
                                ? "#fff"
                                : "black",
                            backgroundColor:
                              val.ACTIVE_KEY == props.headText
                                ? "#1770E0"
                                : "#dedede",
                          }}
                          onClick={() => navigate(val.path)}
                        >
                          <ListItemButton
                            sx={{
                              // width: 150,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {val.icon}
                            <Typography
                              sx={{
                                padding: 0.5,
                              }}
                            >
                              {val.label}
                            </Typography>

                            {/* <ListItemText
                              primary={val.label}
                              sx={{ textAlign: "center" }}
                              className="text-label"
                            /> */}
                          </ListItemButton>
                        </ListItem>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {mobileView == false && (
              <PowerSettingsNewIcon
                style={{
                  color: "white",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={() => {
                  SessionLogout();
                }}
              />
            )}
            {mobileView == true && (
              <Box
                sx={{
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={handleOpen}
              >
                <MenuIcon />
              </Box>
            )}
          </Box>
          {/* <Button>Open modal</Button> */}
          <Modal
            open={open}
            disableAutoFocus
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...style,
                backgroundColor: "#fff",
                // minHeight: "80vh",
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                pb: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <img
                    src={TrueTalentLogo}
                    alt="betacode logo"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="img-responsive logo-img"
                  />
                </div>
                {RoutesArray.map((val) => {
                  console.log("sadjnsadjasd", val, props.headText);

                  return (
                    val.access == true && (
                      <ListItem
                        className="nav-bar-item"
                        disablePadding
                        sx={{
                          borderRadius: 4,
                          overflow: "hidden",
                          backgroundColor:
                            val.ACTIVE_KEY == props.headText
                              ? "#1770E0"
                              : "#dedede",
                          color:
                            val.ACTIVE_KEY == props.headText ? "#fff" : null,
                          mb: 2,
                        }}
                        onClick={() => navigate(val.path)}
                      >
                        <ListItemButton>
                          {val.icon}
                          <ListItemText
                            primary={val.label}
                            sx={{ textAlign: "center" }}
                            className="text-label"
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  );
                })}
                <ListItem
                  className="nav-bar-item"
                  disablePadding
                  sx={{
                    borderRadius: 4,
                    overflow: "hidden",
                    backgroundColor: "#dedede",
                    mb: 2,
                  }}
                  onClick={() => {
                    SessionLogout();
                  }}
                >
                  <ListItemButton>
                    <PowerSettingsNewIcon />
                    <ListItemText
                      primary={"Logout"}
                      sx={{ textAlign: "center" }}
                      className="text-label"
                    />
                  </ListItemButton>
                </ListItem>
              </Box>

              {/* <ChildModal /> */}
            </Box>
          </Modal>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
      <Toolbar />
      {/* <Container>{children}</Container> */}
    </React.Fragment>
  );
};

// export default ;

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
});

export default connect(mapStateToProps, {})(Navbar);
