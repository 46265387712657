export const engagementStatus = [
  { label: "Cold", value: "1" },
  { label: "Warm", value: "2" },
  { label: "Hot", value: "3" },
  { label: "Signed", value: "4" },
  { label: "Dropped", value: "5" },
];

export const meetingMode = [
  { label: "Email", value: "1" },
  { label: "Telephone", value: "2" },
  { label: "Virtual", value: "3" },
  { label: "In Person", value: "4" },
];


const mySalt = "gefdDhYU~GFCG^bfsem67xshah463ksd#$Iny316253)*#D";

export const encodeData = (dataStr) => {
  let data = dataStr.toString();
  const textToChars = (data) => data.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(mySalt).reduce((a, b) => a ^ b, code);
  return data
    .split("")
    .map((c) => textToChars(c)[0])
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};