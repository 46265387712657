const profileLog = require("../assets/icons/profileLog.png");
const search = require("../assets/icons/search.png");
const phone = require("../assets/icons/phone.png");
const linkedIn = require("../assets/icons/linkedIn.png");
const edit = require("../assets/icons/edit.png");
const logo = require("../assets/icons/logo.svg");
const play = require("../assets/icons/play.png");
const del = require("../assets/icons/delete.png");
const searchColor = require("../assets/icons/searchColor.png");
const business_card = require("../assets/icons/business-card.png");
const campaign = require("../assets/icons/campaign.png");
const del1 = require("../assets/icons/del.png");
const excel = require("../assets/icons/excel.png");
const history = require("../assets/icons/history.png");

export default {
  profileLog,
  search,
  phone,
  linkedIn,
  edit,
  logo,
  play,
  del,
  searchColor,
  business_card,
  campaign,
  del1,
  excel,
  history,
};
