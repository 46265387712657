// myAxios.js

// Utility function to convert object to query string
// const objectToQueryString = (params) => {
//   return Object.keys(params)
//     .map(
//       (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
//     )
//     .join("&");
// };

// Custom Axios-like library using XMLHttpRequest
const axios = {
  get: (url, params) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      // const queryString = params ? `?${objectToQueryString(params)}` : "";

      xhr.open("GET", `${url}`, true);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const responseData = JSON.parse(xhr.responseText);
            resolve(responseData);
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };

      xhr.send();
    });
  },

  post: (url, data) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const responseData = JSON.parse(xhr.responseText);
            resolve(responseData);
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };

      xhr.send(JSON.stringify(data));
    });
  },

  // Similar methods for other HTTP methods (e.g., delete) can be added here
};

export default axios;
