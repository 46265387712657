import React, { useState, useEffect } from "react";
import "./SearchPage.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  ListItem,
  Button,
} from "@mui/material";
import CustomInput from "../components/CustomInput/CustomInput";
import Navbar from "../components/Navbar/Navbar";
import { COLORS } from "../constants/theme";
import Icons from "../constants/Icons";
import Lottie from "react-lottie-player";
import noData from "../assets/lottieFiles/noData.json";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { connect, useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import * as XLSX from "xlsx";
import ExcelIcon from "../assets/icons/excel-icon.svg";

function SearchPage(props) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [resData, setResData] = useState([]);
  const [viewAccess, setViewAccess] = useState(null);
  const [empData, setEmpData] = useState({});

  useEffect(() => {
    if (props.employee_data != undefined || props.employee_data.length > 0) {
      let loggedEmp = props.employee_data.find(
        (val) => val.email_id == props.auth_email_id
      );
      setEmpData(loggedEmp);
    }
  }, [props.employee_data]);

  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        if (role == "view") {
          setViewAccess(true);
        }
        if (role == "admin") {
          setViewAccess(false);
        }
      }
    }
  }, [props.employee_data]);
  // ---------teams integration----------

  useEffect(() => {
    ContactDataGetAction();
  }, []);

  //-----------teams integration end------------------

  // const data = [
  //   {
  //     company: "Tata Power",
  //     mgmt: ["Amit Kumar", "Jittesh Singh", "Sonal Singh"],
  //   },
  //   { company: "Tata Motors", mgmt: ["Ritesh Dalmia"] },
  //   { company: "HewLett Packard", mgmt: ["Rohit Kumar", "Priyanka Rao"] },
  // ];
  // ------

  // ----

  const [searchData, setSearchData] = useState([]);
  const [searchDataCopy, setSearchDataCopy] = useState([]);
  useEffect(() => {
    let data = [];
    let companyData = [];
    props.contact_data.map((val) => {
      companyData.push(val.company);
    });

    companyData = [...new Set(companyData)];
    companyData.map((val) => {
      let mgmt = [];
      props.contact_data.map((innerval) => {
        if (val == innerval.company) {
          mgmt.push(innerval);
        }
      });
      data.push({ company: val, mgmt: mgmt });
    });
    setSearchDataCopy(data);
    setSearchData(data);
  }, [props.contact_data]);
  const excelDownload = () => {
    let temp = [...props.contact_data];
    let tempData = [];
    temp.map((val) => {
      let obj = {};
      obj.Name = val.name0;
      obj.Designation = val.designation;
      obj.Company = val.company;
      obj.Email = val.email;
      obj.Mobile = val.mobile;
      obj.Landline = val.landline;
      obj.LinkedIn = val.linkedIn;
      obj.Address = val.address;
      obj.Level = val.level;
      obj.Brand = val.brand;
      obj.City = val.city;
      obj.Type = val.type;
      obj.Mall_Location = val.mallLocation;
      obj.Industry = val.industry;
      obj.Leasing_Member = val.leasingMember;
      obj.Department = val.department;
      tempData.push(obj);
    });
    const worksheet = XLSX.utils.json_to_sheet(tempData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Contact_Data.xlsx");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#eceeef",
        height: "100vh",
      }}
    >
      <Navbar headText="SEARCH_CONTACT" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 2,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            padding: 1,
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "center",
            }}
          >
            Search Contact
          </Typography>
        </Box>
        {viewAccess == false && (
          <Tooltip title="Excel export">
            <button
              data-label="Register"
              className="rainbow-hover"
              onClick={excelDownload}
              style={{
                backgroundColor: "#23a466",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 15,
              }}
            >
              <img src={ExcelIcon} style={{ width: 25, height: 25 }} />
              <span className="sp">Export</span>
            </button>
            {/* <Button
              onClick={() => {
                excelDownload();
              }}
              variant="contained"
              style={{
                cursor: "pointer",
                marginRight: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor:"green"
              }}
            >
              <img
                // className="ln-img"
                style={{
                  marginRight: 2,
                  height: 18,
                  width: 18,
                  marginLeft: 10,
                }}
                src={Icons.excel}
                alt=""
                className="img-responsive"
                tintColor="#fff"
              />
              <Typography
                sx={{
                  color: "white" ,
                  fontWeight: "bold",
                  fontSize: 13,
                  cursor: "pointer",
                  // textDecoration: "underline",
                }}
              >
                Export Contact
              </Typography>
            </Button> */}
          </Tooltip>
        )}
      </Box>

      <Box className="search-container">
        <CustomInput
          autoFocus
          Placeholder={"Search by contact name or company name..."}
          // error={val.emptyFlag}
          icon={Icons.search}
          onChange={(e) => {
            setSearchText(e.target.value);
            let temp = [];
            if (e.target.value == "" || e.target.value == null) {
              setSearchData(searchDataCopy);
              setNoDataFound(false);
            } else {
              searchData.map((val) => {
                if (
                  val.company
                    .toUpperCase()
                    .includes(e.target.value.toUpperCase())
                ) {
                  temp.push(val);
                } else {
                  let tempObj = { company: val.company, mgmt: [] };
                  val.mgmt.map((innerval) => {
                    let tempArray = [];

                    if (
                      innerval.name0
                        .toUpperCase()
                        .includes(e.target.value.toUpperCase())
                    ) {
                      tempObj.mgmt.push(innerval);
                    }
                  });
                  if (tempObj.mgmt.length > 0) {
                    temp.push(tempObj);
                  }
                }
              });
              if (temp.length == 0 && e.target.value) {
                setNoDataFound(true);
              } else {
                setNoDataFound(false);
              }
              setSearchData(temp);
            }
          }}
        />
        {
          <Box className="search-box">
            {searchData.map((val, index) => {
              return (
                <>
                  <Box className="search-card">
                    <Box className="search-card-in">
                      <Typography
                        className="company-text"
                        sx={{
                          fontSize: 16,
                          color: COLORS.gray70,
                          fontWeight: 700,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/companyView", {
                            state: {
                              companyName: val.company,
                            },
                          });
                        }}
                      >
                        {val.company}
                      </Typography>
                      <Box>
                        {val.mgmt.map((innerval, innerIndex) => {
                          return (
                            <Box
                              sx={{
                                ml: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className="contact-bullet"
                                onClick={() => {
                                  navigate("/contactDetails", {
                                    state: {
                                      searchContact: innerval,
                                    },
                                  });
                                }}
                              />
                              <Typography
                                className="contact-text"
                                sx={{
                                  ml: 2,
                                  fontSize: 14,
                                  color: COLORS.gray70,
                                  fontWeight: 400,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/contactDetails", {
                                    state: {
                                      searchContact: innerval,
                                    },
                                  });
                                }}
                              >
                                {innerval.name0}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
        }
        {noDataFound && (
          <Box className="no-data">
            <Lottie
              play
              //   speed={"1"}
              animationData={noData}
              style={{
                width: 250,
                height: 250,
              }}
            />
            <Typography
              sx={{ fontSize: 20, fontWeight: 500, color: "#b7beca" }}
            >
              No Data Found
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
});

export default connect(mapStateToProps, { ContactDataGetAction })(SearchPage);
