import axios from "axios";
import React, { useEffect } from "react";
import clientData from "../constants/clientData";

function Testing() {
  // one time run to fill all the dropdown options in DB table
  const handleEvent = () => {
    const departmentOptions = [
      // {
      //   id: "department",
      //   options: [
      //     { label: "Accounts & Finance", value: "Accounts & Finance" },
      //     { label: "Purchase", value: "Purchase" },
      //     { label: "Accounts ", value: "Accounts " },
      //     { label: "Operations", value: "Operations" },
      //     { label: "Loss Prevention", value: "Loss Prevention" },
      //     { label: "Retail Relation", value: "Retail Relation" },
      //     { label: "SOH", value: "SOH" },
      //     { label: "Security", value: "Security" },
      //     { label: "Architecture", value: "Architecture" },
      //     { label: "MEP", value: "MEP" },
      //     { label: "Marketing ", value: "Marketing " },
      //     { label: "Loyalty", value: "Loyalty" },
      //     { label: "Projects", value: "Projects" },
      //     { label: "E-Commerce", value: "E-Commerce" },
      //     { label: "Executive Secretary", value: "Executive Secretary" },
      //     {
      //       label: "Operations & Buisness Development",
      //       value: "Operations & Buisness Development",
      //     },
      //     {
      //       label: "Retail Design & Delivery",
      //       value: "Retail Design & Delivery",
      //     },
      //     { label: "Leasing", value: "Leasing" },
      //     { label: "Buisness Development", value: "Buisness Development" },
      //     { label: "Recovery & Collection", value: "Recovery & Collection" },
      //     { label: "Human Resource", value: "Human Resource" },
      //     { label: "Commercial", value: "Commercial" },
      //     {
      //       label: "Secretarial & Complaince",
      //       value: "Secretarial & Complaince",
      //     },
      //     { label: "Design & Development", value: "Design & Development" },
      //     { label: "Quantity Surveyor", value: "Quantity Surveyor" },
      //     { label: "Planning & Monitoring", value: "Planning & Monitoring" },
      //     {
      //       label: "PR & Corperate Communication",
      //       value: "PR & Corperate Communication",
      //     },
      //     { label: "Taxation", value: "Taxation" },
      //     { label: "HVAC", value: "HVAC" },
      //     { label: "Interior Designer", value: "Interior Designer" },
      //     { label: "Planning  ", value: "Planning  " },
      //     { label: "Engineering", value: "Engineering" },
      //     { label: "Interior & Finishes", value: "Interior & Finishes" },
      //     { label: "SAP", value: "SAP" },
      //     { label: "Safety", value: "Safety" },
      //     { label: "QA QC", value: "QA QC" },
      //     { label: "ADMIN", value: "ADMIN" },
      //   ],
      // },
      // {
      //   id: "mallLocation",
      //   options: [
      //     { label: "Malad", value: "Malad" },
      //     { label: "Vashi", value: "Vashi" },
      //     { label: "Hyderabad", value: "Hyderabad" },
      //     { label: "Baroda", value: "Baroda" },
      //     { label: "Vizag", value: "Vizag" },
      //     { label: "Corporate Office", value: "Corporate Office" },
      //   ],
      // },

      // {
      //   id: "type",
      //   options: [
      //     { label: "Agency", value: "Agency" },
      //     { label: "Bank", value: "Bank" },
      //     { label: "Brokers", value: "Brokers" },
      //     { label: "Consultants", value: "Consultants" },
      //     { label: "Contractors ", value: "Contractors " },
      //     {
      //       label: "Emergency Response Team",
      //       value: "Emergency Response Team",
      //     },
      //     { label: "Government Officials", value: "Government Officials" },
      //     { label: "Insurance Agents", value: "Insurance Agents" },
      //     { label: "Insurance Companies ", value: "Insurance Companies " },
      //     { label: "Investors", value: "Investors" },
      //     { label: "Licensing ", value: "Licensing " },
      //     {
      //       label: "Maintenance Services (AMC)",
      //       value: "Maintenance Services (AMC)",
      //     },
      //     { label: "Manpower Agencies", value: "Manpower Agencies" },
      //     { label: "NGO", value: "NGO" },
      //     {
      //       label: "Original Equipment Manufacturer (OEM)",
      //       value: "Original Equipment Manufacturer (OEM)",
      //     },
      //     {
      //       label: "Parking Management Team",
      //       value: "Parking Management Team",
      //     },
      //     { label: "Property Consultant ", value: "Property Consultant " },
      //     { label: "Retailers", value: "Retailers" },
      //     { label: "Security Personnel", value: "Security Personnel" },
      //     { label: "Suppliers/Traders", value: "Suppliers/Traders" },
      //     { label: "Vendor", value: "Vendor" },
      //   ],
      // },
      {
        id: "industry",
        options: [
          // { label: "CCTV", value: "CCTV", typeForIndustry: "Agency" },
          // { label: "Creative", value: "Creative", typeForIndustry: "Agency" },
          // {
          //   label: "Digital & Social Media",
          //   value: "Digital & Social Media",
          //   typeForIndustry: "Agency",
          // },
          // { label: "ERT", value: "ERT", typeForIndustry: "Agency" },
          // {
          //   label: "Horticulture",
          //   value: "Horticulture",
          //   typeForIndustry: "Agency",
          // },
          // {
          //   label: "Housekeeping",
          //   value: "Housekeeping",
          //   typeForIndustry: "Agency",
          // },
          // {
          //   label: "Influencer",
          //   value: "Influencer",
          //   typeForIndustry: "Agency",
          // },
          // { label: "Media", value: "Media", typeForIndustry: "Agency" },
          // {
          //   label: "Models & Casting",
          //   value: "Models & Casting",
          //   typeForIndustry: "Agency",
          // },
          // { label: "Parking", value: "Parking", typeForIndustry: "Agency" },
          // {
          //   label: "Pest Control",
          //   value: "Pest Control",
          //   typeForIndustry: "Agency",
          // },
          // {
          //   label: "Production",
          //   value: "Production",
          //   typeForIndustry: "Agency",
          // },
          // {
          //   label: "Public Relation",
          //   value: "Public Relation",
          //   typeForIndustry: "Agency",
          // },
          // { label: "Security", value: "Security", typeForIndustry: "Agency" },
          // {
          //   label: "Waste management ",
          //   value: "Waste management ",
          //   typeForIndustry: "Agency",
          // },
          // {
          //   label: "Website & Development",
          //   value: "Website & Development",
          //   typeForIndustry: "Agency",
          // },
          // {
          //   label: "Audit and Assurance",
          //   value: "Audit and Assurance",
          //   typeForIndustry: "Auditor",
          // },
          // {
          //   label: "Statuory Audit",
          //   value: "Statuory Audit",
          //   typeForIndustry: "Auditor",
          // },
          // { label: "Banking", value: "Banking", typeForIndustry: "Bank" },
          // {
          //   label: "Buisness Development",
          //   value: "Buisness Development",
          //   typeForIndustry: "Brokers",
          // },
          // {
          //   label: "Architectural Design",
          //   value: "Architectural Design",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Auditors",
          //   value: "Auditors",
          //   typeForIndustry: "Consultants",
          // },
          // { label: "BIM", value: "BIM", typeForIndustry: "Consultants" },
          // {
          //   label: "Buisness Development",
          //   value: "Buisness Development",
          //   typeForIndustry: "Consultants ",
          // },
          // {
          //   label: "Cost Consultants",
          //   value: "Cost Consultants",
          //   typeForIndustry: "Consultants",
          // },
          // { label: "Façade", value: "Façade", typeForIndustry: "Consultants" },
          // {
          //   label: "Fountain Consultant",
          //   value: "Fountain Consultant",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Geotechnical Surveyor",
          //   value: "Geotechnical Surveyor",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Green Building",
          //   value: "Green Building",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Interior Design",
          //   value: "Interior Design",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Land Surveyor",
          //   value: "Land Surveyor",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Landscape Design",
          //   value: "Landscape Design",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Legal consultants",
          //   value: "Legal consultants",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Liaisoning Architect",
          //   value: "Liaisoning Architect",
          //   typeForIndustry: "Consultants",
          // },
          // { label: "Lifts", value: "Lifts", typeForIndustry: "Consultants" },
          // {
          //   label: "Lighting",
          //   value: "Lighting",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "MEP Design",
          //   value: "MEP Design",
          //   typeForIndustry: "Consultants",
          // },
          // { label: "MOEF ", value: "MOEF ", typeForIndustry: "Consultants" },
          // { label: "PMC", value: "PMC", typeForIndustry: "Consultants" },
          // {
          //   label: "Signage Design",
          //   value: "Signage Design",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Statutoty",
          //   value: "Statutoty",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Structural Design",
          //   value: "Structural Design",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Tax, Due Deligence etc.",
          //   value: "Tax, Due Deligence etc.",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Traffic",
          //   value: "Traffic",
          //   typeForIndustry: "Consultants",
          // },
          // {
          //   label: "Tree consultant",
          //   value: "Tree consultant",
          //   typeForIndustry: "Consultants",
          // },
          // { label: "CCTV", value: "CCTV", typeForIndustry: "Contractors " },
          // { label: "Civil", value: "Civil", typeForIndustry: "Contractors " },
          // {
          //   label: "Electrical",
          //   value: "Electrical",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Excavation",
          //   value: "Excavation",
          //   typeForIndustry: "Contractors ",
          // },
          // { label: "Façade", value: "Façade", typeForIndustry: "Contractors " },
          // { label: "FAPA", value: "FAPA", typeForIndustry: "Contractors " },
          // {
          //   label: "Fire fighting",
          //   value: "Fire fighting",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Glass railing",
          //   value: "Glass railing",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Hard scaping",
          //   value: "Hard scaping",
          //   typeForIndustry: "Contractors ",
          // },
          // { label: "HVAC", value: "HVAC", typeForIndustry: "Contractors " },
          // {
          //   label: "Interiors",
          //   value: "Interiors",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Marketing/Event partners",
          //   value: "Marketing/Event partners",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Misc. (Software development/IT infrastructure)",
          //   value: "Misc. (Software development/IT infrastructure)",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Painting",
          //   value: "Painting",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Parking Management",
          //   value: "Parking Management",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Plumbing",
          //   value: "Plumbing",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Real Estate",
          //   value: "Real Estate",
          //   typeForIndustry: "Contractor",
          // },
          // {
          //   label: "Soft scaping",
          //   value: "Soft scaping",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "STP/WTP",
          //   value: "STP/WTP",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Structure",
          //   value: "Structure",
          //   typeForIndustry: "Contractors ",
          // },
          // {
          //   label: "Waterproofing",
          //   value: "Waterproofing",
          //   typeForIndustry: "Contractors ",
          // },
          {
            label: "Emergency Services",
            value: "Emergency Services",
            typeForIndustry: "Emergency Response Team",
          },
          {
            label: "Collector office",
            value: "Collector office",
            typeForIndustry: "Government Officials",
          },
          {
            label: "Fire Brigade",
            value: "Fire Brigade",
            typeForIndustry: "Government Officials",
          },
          {
            label: "IPR ",
            value: "IPR ",
            typeForIndustry: "Government Officials",
          },
          {
            label: "Police",
            value: "Police",
            typeForIndustry: "Government Officials",
          },
          {
            label: "Police",
            value: "Police",
            typeForIndustry: "Government Officials",
          },
          {
            label: "Traffic",
            value: "Traffic",
            typeForIndustry: "Government Officials",
          },
          {
            label: "VMC",
            value: "VMC",
            typeForIndustry: "Government Officials",
          },
          {
            label: "Ward office",
            value: "Ward office",
            typeForIndustry: "Government Officials",
          },
          {
            label: "Insurance ",
            value: "Insurance ",
            typeForIndustry: "Insurance Agents",
          },
          {
            label: "Insurance",
            value: "Insurance",
            typeForIndustry: "Insurance Companies ",
          },
          {
            label: "Buisness Development",
            value: "Buisness Development",
            typeForIndustry: "Investors",
          },
          {
            label: "Internal licensing",
            value: "Internal licensing",
            typeForIndustry: "Licensing ",
          },
          {
            label: "BMC, Police, Fire",
            value: "BMC, Police, Fire",
            typeForIndustry: "Licensing ",
          },
          {
            label: "BMS",
            value: "BMS",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "CCTV",
            value: "CCTV",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Chillers",
            value: "Chillers",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Cooling tower",
            value: "Cooling tower",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "DG sets",
            value: "DG sets",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Dish washer",
            value: "Dish washer",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "EPBAX",
            value: "EPBAX",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "FAPA",
            value: "FAPA",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Fire fighting",
            value: "Fire fighting",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Lifts/Escalators",
            value: "Lifts/Escalators",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Manpower (Security/Gardeners/HK/Engg/Parking/CCTV & ERT)",
            value: "Manpower (Security/Gardeners/HK/Engg/Parking/CCTV & ERT)",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Misc.",
            value: "Misc.",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Mystery Audit",
            value: "Mystery Audit",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "OWC",
            value: "OWC",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Parking Solutions",
            value: "Parking Solutions",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "STP",
            value: "STP",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Tank cleaning system",
            value: "Tank cleaning system",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Walkie Talkie",
            value: "Walkie Talkie",
            typeForIndustry: "Maintenance Services (AMC)",
          },
          {
            label: "Facility Management",
            value: "Facility Management",
            typeForIndustry: "Manpower Agencies",
          },
          { label: "CSR", value: "CSR", typeForIndustry: "NGO" },
          {
            label: "AHU",
            value: "AHU",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "Chillers",
            value: "Chillers",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "Cooling Towers",
            value: "Cooling Towers",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "DG sets",
            value: "DG sets",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "Escalators",
            value: "Escalators",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "FCU",
            value: "FCU",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "Lifts ",
            value: "Lifts ",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "Transformers",
            value: "Transformers",
            typeForIndustry: "Original Equipment Manufacturer (OEM)",
          },
          {
            label: "Parking Management",
            value: "Parking Management",
            typeForIndustry: "Parking Management Team",
          },
          {
            label: "Regional",
            value: "Regional",
            typeForIndustry: "Property Consultant ",
          },
          {
            label: "International",
            value: "International",
            typeForIndustry: "Property Consultant ",
          },
          {
            label: "National",
            value: "National",
            typeForIndustry: "Property Consultant ",
          },
          {
            label: " Foodcourt",
            value: " Foodcourt",
            typeForIndustry: "Retailers",
          },
          {
            label: " Home Improvement",
            value: " Home Improvement",
            typeForIndustry: "Retailers",
          },
          {
            label: "Accessories",
            value: "Accessories",
            typeForIndustry: "Retailers",
          },
          { label: "Apparel", value: "Apparel", typeForIndustry: "Retailers" },
          {
            label: "Books and Media",
            value: "Books and Media",
            typeForIndustry: "Retailers",
          },
          {
            label: "Cosmetics",
            value: "Cosmetics",
            typeForIndustry: "Retailers",
          },
          {
            label: "Department Stores",
            value: "Department Stores",
            typeForIndustry: "Retailers",
          },
          {
            label: "Electronics",
            value: "Electronics",
            typeForIndustry: "Retailers",
          },
          {
            label: "Entertainment",
            value: "Entertainment",
            typeForIndustry: "Retailers",
          },
          { label: "Eyewear", value: "Eyewear", typeForIndustry: "Retailers" },
          {
            label: "Family entertainment center",
            value: "Family entertainment center",
            typeForIndustry: "Retailers",
          },
          { label: "Food", value: "Food", typeForIndustry: "Retailers" },
          {
            label: "Footwear",
            value: "Footwear",
            typeForIndustry: "Retailers",
          },
          {
            label: "Health and Beauty",
            value: "Health and Beauty",
            typeForIndustry: "Retailers",
          },
          {
            label: "Home Improvement",
            value: "Home Improvement",
            typeForIndustry: "Retailers",
          },
          {
            label: "Hypermarket",
            value: "Hypermarket",
            typeForIndustry: "Retailers",
          },
          {
            label: "Jewellery & Watches",
            value: "Jewellery & Watches",
            typeForIndustry: "Retailers",
          },
          {
            label: "Multiplex",
            value: "Multiplex",
            typeForIndustry: "Retailers",
          },
          {
            label: "Perfumes & Cosmetics",
            value: "Perfumes & Cosmetics",
            typeForIndustry: "Retailers",
          },
          {
            label: "Pet Supplies",
            value: "Pet Supplies",
            typeForIndustry: "Retailers",
          },
          {
            label: "Restaurent",
            value: "Restaurent",
            typeForIndustry: "Retailers",
          },
          {
            label: "Spa, Salons & Services",
            value: "Spa, Salons & Services",
            typeForIndustry: "Retailers",
          },
          {
            label: "Sports goods equipments",
            value: "Sports goods equipments",
            typeForIndustry: "Retailers",
          },
          {
            label: "Toys, Gifts, Cards & Novelties",
            value: "Toys, Gifts, Cards & Novelties",
            typeForIndustry: "Retailers",
          },
          {
            label: "Travel & Leisure",
            value: "Travel & Leisure",
            typeForIndustry: "Retailers",
          },
          {
            label: "Security",
            value: "Security",
            typeForIndustry: "Security Personnel",
          },
          {
            label: "Artifacts",
            value: "Artifacts",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Cement",
            value: "Cement",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Chandeliers",
            value: "Chandeliers",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Consumables (HK/Engg/Misc.)",
            value: "Consumables (HK/Engg/Misc.)",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "CP/Sanitary Fixtures",
            value: "CP/Sanitary Fixtures",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Doors",
            value: "Doors",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Furniture",
            value: "Furniture",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Lights",
            value: "Lights",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Marbles",
            value: "Marbles",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "PT/HT strands",
            value: "PT/HT strands",
            typeForIndustry: "Suppliers/Traders",
          },
          { label: "RMC", value: "RMC", typeForIndustry: "Suppliers/Traders" },
          {
            label: "Steel",
            value: "Steel",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Tile",
            value: "Tile",
            typeForIndustry: "Suppliers/Traders",
          },
          {
            label: "Advertising Company",
            value: "Advertising Company",
            typeForIndustry: "Vendor",
          },
          { label: "Artwork", value: "Artwork", typeForIndustry: "Vendor" },
          {
            label: "Buisness Development",
            value: "Buisness Development",
            typeForIndustry: "Vendor",
          },
          {
            label: "Carpentry ",
            value: "Carpentry ",
            typeForIndustry: "Vendor",
          },
          { label: "Ceiling", value: "Ceiling", typeForIndustry: "Vendor" },
          { label: "Civil", value: "Civil", typeForIndustry: "Vendor" },
          {
            label: "Cladding - External & Internal",
            value: "Cladding - External & Internal",
            typeForIndustry: "Vendor",
          },
          {
            label: "CP - Sanitaryware",
            value: "CP - Sanitaryware",
            typeForIndustry: "Vendor",
          },
          {
            label: "Creative ",
            value: "Creative ",
            typeForIndustry: "Vendor ",
          },
          { label: "Creative ", value: "Creative ", typeForIndustry: "Vendor" },
          {
            label: "Customer Support",
            value: "Customer Support",
            typeForIndustry: "Vendor",
          },
          {
            label: "Development ",
            value: "Development ",
            typeForIndustry: "Vendor ",
          },
          {
            label: "Digital & Social Media",
            value: "Digital & Social Media",
            typeForIndustry: "Vendor ",
          },
          {
            label: "Digital Marketing",
            value: "Digital Marketing",
            typeForIndustry: "Vendor",
          },
          {
            label: "Digital Screens",
            value: "Digital Screens",
            typeForIndustry: "Vendor",
          },
          { label: "Doors ", value: "Doors ", typeForIndustry: "Vendor" },
          {
            label: "ECom Consulting",
            value: "ECom Consulting",
            typeForIndustry: "Vendor",
          },
          {
            label: "Engineering",
            value: "Engineering",
            typeForIndustry: "Vendor",
          },
          {
            label: "Escalators & Travelators",
            value: "Escalators & Travelators",
            typeForIndustry: "Vendor",
          },
          { label: "Event ", value: "Event ", typeForIndustry: "Vendor" },
          {
            label: "Event Company",
            value: "Event Company",
            typeForIndustry: "Vendor",
          },
          { label: "Events ", value: "Events ", typeForIndustry: "Vendor " },
          {
            label: "Fire doors",
            value: "Fire doors",
            typeForIndustry: "Vendor",
          },
          { label: "Flooring ", value: "Flooring ", typeForIndustry: "Vendor" },
          { label: "Furniture", value: "Furniture", typeForIndustry: "Vendor" },
          { label: "Glazing", value: "Glazing", typeForIndustry: "Vendor" },
          {
            label: "HK Consumables",
            value: "HK Consumables",
            typeForIndustry: "Vendor",
          },
          { label: "Laminates", value: "Laminates", typeForIndustry: "Vendor" },
          { label: "Lifts", value: "Lifts", typeForIndustry: "Vendor" },
          { label: "Lighting", value: "Lighting", typeForIndustry: "Vendor" },
          { label: "Logistic", value: "Logistic", typeForIndustry: "Vendor" },
          {
            label: "Manpower Agency",
            value: "Manpower Agency",
            typeForIndustry: "Vendor",
          },
          {
            label: "Marketing / Decorative",
            value: "Marketing / Decorative",
            typeForIndustry: "Vendor",
          },
          {
            label: "Operations",
            value: "Operations",
            typeForIndustry: "Vendor",
          },
          { label: "Painting", value: "Painting", typeForIndustry: "Vendor" },
          {
            label: "Payment Gateway",
            value: "Payment Gateway",
            typeForIndustry: "Vendor",
          },
          {
            label: "Photographer",
            value: "Photographer",
            typeForIndustry: "Vendor",
          },
          { label: "Plants", value: "Plants", typeForIndustry: "Vendor" },
          { label: "Plumbing ", value: "Plumbing ", typeForIndustry: "Vendor" },
          {
            label: "Printing & Mounting ",
            value: "Printing & Mounting ",
            typeForIndustry: "Vendor",
          },
          { label: "Railing", value: "Railing", typeForIndustry: "Vendor" },
          {
            label: "Security & Safety Products",
            value: "Security & Safety Products",
            typeForIndustry: "Vendor",
          },
          { label: "Signages", value: "Signages", typeForIndustry: "Vendor" },
          { label: "Skylights", value: "Skylights", typeForIndustry: "Vendor" },
          {
            label: "Soild surfaces",
            value: "Soild surfaces",
            typeForIndustry: "Vendor",
          },
          {
            label: "Stainless Steel",
            value: "Stainless Steel",
            typeForIndustry: "Vendor",
          },
          {
            label: "Videographer",
            value: "Videographer",
            typeForIndustry: "Vendor",
          },
          {
            label: "Water Tankers",
            value: "Water Tankers",
            typeForIndustry: "Vendor",
          },
          {
            label: "Web Development",
            value: "Web Development",
            typeForIndustry: "Vendor",
          },
        ],
      },
      // {
      //   id: "city",
      //   options: [
      //     { label: "Delhi", value: "Delhi" },
      //     { label: "Mumbai", value: "Mumbai" },
      //     { label: "Bangalore", value: "Bangalore" },
      //     { label: "Hyderabad", value: "Hyderabad" },
      //     { label: "Chennai", value: "Chennai" },
      //     { label: "Vadodara", value: "Vadodara" },
      //     { label: "Kolkata", value: "Kolkata" },
      //   ],
      // },
    ];

    let authData = new FormData();
    authData.append("grant_type", "client_credentials");
    authData.append("client_id", clientData.client_id);
    authData.append("client_secret", clientData.client_secret);
    authData.append("resource", clientData.resource);

    axios
      .post(
        `https://accounts.accesscontrol.windows.net/${clientData.resourceId}/tokens/OAuth/2/`,
        authData
      )
      .then((response) => {
        postDdData(response.data.access_token);
      })
      .catch((error) => {
        console.log(error);
      });
    const postDdData = (token) => {
      departmentOptions.map((val) => {
        val.options.map((inVal) => {
          inVal.dropdownId = val.id;
          delete inVal.value;

          axios
            .post(
              `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('dropdownOptions')/items`,
              {
                __metadata: { type: "SP.Data.DropdownOptionsListItem" },
                ...inVal,
              },
              {
                headers: {
                  "Content-Type": "application/json;odata=verbose",
                  Accept: "application/json;odata=verbose",
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((response) => {
              console.log("completed");
            });
        });
      });
    };
  };
  return (
    <div
      onClick={() => {
        console.log("dd added");
        handleEvent();
      }}
    >
      Testing
    </div>
  );
}

export default Testing;

// -----------------local image to base64-----------------------

// // file:///C:/Users/JOYEET~1/AppData/Local/Temp/msohtmlclip1/01/clip_image002.jpg
// // ../../../../AppData/Local/Temp/msohtmlclip1/01/clip_image002.jpg
// import React, { useState, useEffect } from "react";
// import IMAGE from "../assets/images/bg1.png";
// // import  *  from "path";
// // var path = require("path");

// function Testing() {
//   const [dummy, setDummy] = useState();
//   // Assuming your HTML content is stored in a variable called 'htmlContent'
//   const htmlContent = `<p><span lang="EN-IN" style="font-size: 15px; font-family: Calibri, sans-serif;">
//     <v:shapetype id="_x0000_t75" coordsize="21600,21600" o:spt="75" o:preferrelative="t" path="m@4@5l@4@11@9@11@9@5xe" filled="f" stroked="f">
//         <v:stroke joinstyle="miter">
//         <v:formulas>
//             <v:f eqn="if lineDrawn pixelLineWidth 0">
//             <v:f eqn="sum @0 1 0">
//             <v:f eqn="sum 0 0 @1">
//             <v:f eqn="prod @2 1 2">
//             <v:f eqn="prod @3 21600 pixelWidth">
//             <v:f eqn="prod @3 21600 pixelHeight">
//             <v:f eqn="sum @0 0 1">
//             <v:f eqn="prod @6 1 2">
//             <v:f eqn="prod @7 21600 pixelWidth">
//             <v:f eqn="sum @8 21600 0">
//             <v:f eqn="prod @7 21600 pixelHeight">
//             <v:f eqn="sum @10 21600 0">
//         </v:f></v:f></v:f></v:f></v:f></v:f></v:f></v:f></v:f></v:f></v:f></v:f></v:formulas>
//         <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect">
//         <o:lock v:ext="edit" aspectratio="t">
//     </o:lock></v:path></v:stroke></v:shapetype>
//     <v:shape id="_x0000_i1025" type="#_x0000_t75" alt="Top 70 Procrastination Quotes (2024 Update) - QuoteFancy" style="width:462pt;height:260.25pt">
//         <v:imagedata src="C:/Users/JOYEET~1/AppData/Local/Temp/msohtmlclip1/01/clip_image001.jpg" o:href="cid:image001.jpg@01DA6BC0.86249C10">
//     </v:imagedata></v:shape>
//     <!--[endif]----><img width="616" height="347" src="file:///C:/Users/Joyeeta Brahmachary/AppData/Local/Temp/msohtmlclip1/01/clip_image002.jpg" style="height: 3.611in; width: 6.416in;" alt="Top 70 Procrastination Quotes (2024 Update) - QuoteFancy" v:shapes="_x0000_i1025"></span></p>`;

//   // Regular expression to match <img> tags and capture their 'src' attribute
//   const imgRegex = /<img[^>]+src="([^">]+)"/g;

//   // Replace image paths with base64 representations
//   async function replaceAsync(str, regex, asyncFn) {
//     const promises = [];
//     str.replace(regex, (full, ...args) => {
//       promises.push(asyncFn(full, ...args));
//       return full;
//     });
//     const data = await Promise.all(promises);
//     return str.replace(regex, () => data.shift());
//   }
//   // const htmlWithBase64 = htmlContent.replace(
//   //   imgRegex,
//   //   async (matched, imgSrc) => {
//   //     // Assuming you have a function to convert image to base64
//   //     // Replace the 'src' attribute with the base64 representation

//   //     const base64Image = await convertToBase64(imgSrc);
//   //     console.log("hsjdksdl", base64Image);
//   //     return `<img src="${base64Image}"`;
//   //   }
//   // );

//   // Function to convert image to base64
//   async function convertToBase64(imgSrc) {
//     console.log("asdasdas", imgSrc);

//     imgSrc = imgSrc.replace("file:///", "");
//     let Data = "";
//     return await toDataURL(imgSrc)
//       .then((response) => {
//         console.log("sdsadsad", response);
//         return response;
//       })
//       .catch((err) => {
//         return err;
//       });
//     // toDataURL(
//     //   // "https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0",
//     //   imgSrc,
//     //   function (dataUrl) {
//     //     // setDummy(dataUrl);
//     //     Data = dataUrl;
//     //     console.log("RESULT:", Data);
//     //   }
//     // );

//     // Your logic to convert image to base64
//     // This can be done using FileReader API or any other method
//   }

//   //   function toDataURL(url, callback) {
//   //     console.log("Asdsadsad url got", url);
//   //     var xhr = new XMLHttpRequest();
//   //     xhr.onload = function () {
//   //       var reader = new FileReader();
//   //       reader.onloadend = function () {
//   //         callback(reader.result);
//   //       };
//   //       reader.readAsDataURL(xhr.response);
//   //     };
//   //     xhr.open("GET", url);
//   //     xhr.responseType = "blob";
//   //     xhr.send();
//   //   }
//   function toDataURL(url) {
//     console.log("Asdsadsad url got", url);
//     return new Promise((resolve, reject) => {
//       var xhr = new XMLHttpRequest();
//       xhr.open("GET", url);
//       xhr.responseType = "blob";
//       xhr.onload = function () {
//         var reader = new FileReader();
//         reader.onloadend = function () {
//           resolve(reader.result);
//         };
//         reader.readAsDataURL(xhr.response);
//       };
//       xhr.onerror = function () {
//         // reject(new Error("Failed to load image"));
//         reject(url);
//       };

//       xhr.send();
//     });
//   }
//   // Example usage:
//   //   async function getImageData() {
//   //     try {
//   //       const imageData = await toDataURL("your_image_url_here");
//   //       //   console.log("Base64 image data:", imageData);
//   //       return imageData;
//   //       // Use the imageData as needed
//   //     } catch (error) {
//   //       console.error("Error:", error.message);
//   //     }
//   //   }

//   // Call the function
//   //   console.log("ASdsadasd", getImageData());

//   // Now, htmlWithBase64 contains the HTML with <img> tags replaced with base64 images

//   replaceAsync(htmlContent, imgRegex, async (matched, imgSrc) => {
//     // Assuming you have a function to convert image to base64
//     // Replace the 'src' attribute with the base64 representation

//     const base64Image = await convertToBase64(imgSrc);
//     console.log("hsjdksdl", base64Image);
//     setDummy(base64Image);
//     return `<img src="${base64Image}"`;
//   }).then((replacedStr) => {
//     console.log("kdsuhfkdh", replacedStr);
//   });

//   return (
//     <div>
//       {/* <img src={demo} /> */}
//       <img src={IMAGE} />
//     </div>
//   );
// }

// export default Testing;
