import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const ContactDataGetAction = (AuthID) => (dispatch) => {
  const getSharepointData = (token) => {


    axios
      .post(`${AxiosDefaultPort}apis/sharepoint/contactDataGet`, {
        token,
        AUTH_ID: AuthID,
      })
      .then((response) => {
        console.log("asdbasdasdas", response.data);

        let temp = [...response.data.value];
        temp = temp.filter((val) => val.isRemoved == "No");
        temp.map((val) => {
          val.checked = false;
          val.ID = val.ID.toString();
        });

        dispatch({
          type: "CONTACT_DATA_GET",
          payload: temp,
        });
      });
  };
  getAccessToken(getSharepointData);
};
