import React, { useState, useEffect, useRef } from "react";
import "./ContactCapture.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Modal,
  Divider,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../components/CustomInput/CustomInput";
import Navbar from "../components/Navbar/Navbar";
import { COLORS } from "../constants/theme";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomDropdown from "../components/CustomDropDown/CustomDropDown";
import downloadImg from "../assets/icons/download.svg";
import UploadImg from "../assets/icons/upload.svg";

import axios from "axios";
// import cogoToast from "cogo-toast";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "../Redux/Action/MeetingDataGetAction";
import { DdOptionsGetAction } from "../Redux/Action/DdOptionsGetAction";
import { connect, useDispatch } from "react-redux";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Tesseract from "tesseract.js";
import platform from "platform";
import Lottie from "react-lottie-player";
import loadingAnime from "../assets/lottieFiles/loader.json";
import { RxCross1 } from "react-icons/rx";
import { AxiosDefaultPort } from "../constants/Axios";
import toast, { Toaster } from "react-hot-toast";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { TbEdit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import Icons from "../constants/Icons";
import getAccessToken from "../constants/getAccessToken";
import clientData from "../constants/clientData";
// import * as microsoftTeams from "@microsoft/teams-js";
import useWindowSize from "../components/Navbar/useWindowSize";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ReactDataTable from "../components/ReactDataTable/ReactDataTable";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AXIOS from "../utils/AXIOS";
import { engagementStatus, meetingMode } from "../utils/Constants";

const mySalt = "dcbuyft3i476ofl5c8j9m0^&%JSDCYhjs%@#$kfjvkf";

const importStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CameraComponent = () => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div>
      {error && <p>{error.message}</p>}
      <video ref={videoRef} autoPlay playsInline />
      <button onClick={startCamera}>Start Camera</button>
    </div>
  );
};

function ContactCapture(props) {
  const dispatch = useDispatch();
  const [inputList, setInputList] = useState([]);
  const [scanModal, setScanModal] = useState(false);
  const [scanProcessModal, setScanProcessModal] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [isCamera, setIsCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [industryList, setIndustryList] = useState({
    label: "Industry *",
    // value: { label: "", value: "" },
    value: "",
    type: "dropDown",
    options: [],
    ID: "industry",
    table: "contact",
    emptyFlag: false,
  });
  const [empData, setEmpData] = useState({});
  const [typeData, setTypeData] = useState({});
  const [addOptionFlag, setAddOptionFlag] = useState(false);
  const [addOptionLabel, setAddOptionLabel] = useState("");
  const [editDdModal, setEditDdModal] = useState({
    location: false,
    type: false,
    department: false,
    industry: false,
    city: false,
    department: false,
  });

  // const [userDetails, setUserDetails] = useState({});
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   microsoftTeams.app.initialize().then(() => {
  //     microsoftTeams.authentication.getAuthToken({
  //       successCallback: (token) => {

  //         getUserDetails(token);
  //       },
  //       failureCallback: (error) => {
  //         console.error("Error getting token:", error);
  //         setError(error);
  //       },
  //     });
  //   });
  // }, []);

  // const getUserDetails = async (token) => {
  //   // Decode JWT to extract the payload
  //   var family_name;
  //   var upn;
  //   var given_name;
  //   const base64Url = token.split(".")[1]; // Get the payload part of the token
  //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Normalize base64 string
  //   const payload = decodeURIComponent(
  //     window
  //       .atob(base64)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );
  //   const decodedToken = JSON.parse(payload);

  //   // Extract 'oid' from the decoded token
  //   if (decodedToken != null) {
  //     family_name = decodedToken?.family_name;
  //     given_name = decodedToken?.given_name;
  //     upn = decodedToken?.upn;
  //   }
  //   // Instead of fetching user details, directly set OID and token in state
  //   setUserDetails({
  //     family_name: family_name,
  //     given_name: given_name, // Store the token directly
  //     upn: upn,
  //   });
  // };
  // get logged employee data
  // ===============
  const [selectedDate, setSelectedDate] = useState("");
  // ===============
  // useEffect(() => {
  //   setCurrentCrManager();
  // }, [
  //   props.employee_data,
  //   props.auth_name,
  //   props.auth_email_id,
  //   props.auth_id,
  // ]);

  const setCurrentCrManager = () => {
    let tempMeetingList = [...inputData];
    const crManagerIndex = tempMeetingList.findIndex(
      (item) => item.ID == "crManager"
    );

    if (crManagerIndex) {
      tempMeetingList[crManagerIndex].options = props.employee_data;
      tempMeetingList[crManagerIndex].value = {
        label: props.auth_name,
        value: props.auth_email_id,
        _id: props.auth_id,
      };
      setInputData(tempMeetingList);
    }
  };
  useEffect(() => {
    if (props.employee_data != undefined || props.employee_data.length > 0) {
      let loggedEmp = props.employee_data.find(
        (val) => val.email_id == props.auth_email_id
      );
      setEmpData(loggedEmp);
    }
    let tempInput = [...inputData];
    let ind = tempInput.findIndex((val) => val.ID == "crManager");
    tempInput[ind].value = props.auth_name;
    setInputList(tempInput);
  }, [props.employee_data]);
  // --------------------------------------
  let setCompanyDd = () => {
    let temp = [...props.contact_data];
    let tempCompany = [];
    temp.map((val) => {
      tempCompany.push({ label: val.company, value: val.company });
    });
    let jsonObject = tempCompany.map(JSON.stringify);
    let tempCompanySet = new Set(jsonObject);

    let companyData = Array.from(tempCompanySet).map(JSON.parse);
    companyData.sort(function (a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    companyData.push({ label: "Other", value: "other" });
    let tempInputData = [...inputData];

    const index = tempInputData.findIndex((val) => val.ID == "company");
    if (index != -1) {
      tempInputData[index].options = companyData;
    }
    tempInputData.map((val) => {
      if (val.ID != "crManager") {
        val.value = "";
      }
    });
    setCompanyList(companyData);
    // let tempInput = [...inputList];
    // if (inputList.length > 0) {
    //   let index = tempInput.findIndex((val) => val.ID == "company");
    //   tempInput[index].options = companyData;

    // }
    setInputList(tempInputData);
  };
  // get company dropdown options
  useEffect(() => {
    setCompanyDd();
    // ---------
  }, [props]);
  // let setIndDd = () => {
  //   if (inputList.length > 0) {
  //     let tempInput = [...inputList];

  //     let indindex = tempInput.findIndex((x) => x.ID == "industry");
  //     let indData = props.dd_options.filter(
  //       (val) =>
  //         val.typeForIndustry ==
  //         tempInput.find((x) => x.ID == "type")?.value?.label
  //     );
  //     tempInput[indindex].options = indData;
  //     setInputList(tempInput);
  //   }
  // };
  // useEffect(() => {
  //   setIndDd();
  // }, [props.dd_options]);

  const navigate = useNavigate();
  const departmentOptions = [
    { label: "Accounts & Finance", value: "Accounts & Finance" },
    { label: "Purchase", value: "Purchase" },
    { label: "Accounts ", value: "Accounts " },
    { label: "Operations", value: "Operations" },
    { label: "Loss Prevention", value: "Loss Prevention" },
    { label: "Retail Relation", value: "Retail Relation" },
    { label: "SOH", value: "SOH" },
    { label: "Security", value: "Security" },
    { label: "Architecture", value: "Architecture" },
    { label: "MEP", value: "MEP" },
    { label: "Marketing ", value: "Marketing " },
    { label: "Loyalty", value: "Loyalty" },
    { label: "Projects", value: "Projects" },
    { label: "E-Commerce", value: "E-Commerce" },
    { label: "Executive Secretary", value: "Executive Secretary" },
    {
      label: "Operations & Buisness Development",
      value: "Operations & Buisness Development",
    },
    {
      label: "Retail Design & Delivery",
      value: "Retail Design & Delivery",
    },
    { label: "Leasing", value: "Leasing" },
    { label: "Buisness Development", value: "Buisness Development" },
    { label: "Recovery & Collection", value: "Recovery & Collection" },
    { label: "Human Resource", value: "Human Resource" },
    { label: "Commercial", value: "Commercial" },
    {
      label: "Secretarial & Complaince",
      value: "Secretarial & Complaince",
    },
    { label: "Design & Development", value: "Design & Development" },
    { label: "Quantity Surveyor", value: "Quantity Surveyor" },
    { label: "Planning & Monitoring", value: "Planning & Monitoring" },
    {
      label: "PR & Corperate Communication",
      value: "PR & Corperate Communication",
    },
    { label: "Taxation", value: "Taxation" },
    { label: "HVAC", value: "HVAC" },
    { label: "Interior Designer", value: "Interior Designer" },
    { label: "Planning  ", value: "Planning  " },
    { label: "Engineering", value: "Engineering" },
    { label: "Interior & Finishes", value: "Interior & Finishes" },
    { label: "SAP", value: "SAP" },
    { label: "Safety", value: "Safety" },
    { label: "QA QC", value: "QA QC" },
    { label: "ADMIN", value: "ADMIN" },
  ];

  // const headers = [
  //   "location",
  //   "name0",
  //   "meetingDate",
  //   "meetingTime",
  //   "type",
  //   "designation",
  //   "crManager",
  //   "brand",
  //   "email",
  //   "department",
  //   "industry",
  //   "mobile",
  //   "remarks",
  //   "company",
  //   "landline",
  //   "linkedIn",
  //   "address",
  //   "level",
  //   "city",
  // ]; // Example headers

  // const [inputData, setInputData] = useState(
  const [inputData, setInputData] = useState([
    // {
    //   label: "Location",
    //   required: true,
    //   // value: { label: "", value: "" },
    //   value: "",
    //   type: "dropDown",
    //   options: [],
    //   ID: "location",
    //   table: "contact",
    //   emptyFlag: false,
    //   MOBILE_INDEX: 1,
    //   WEB_INDEX: 1,
    // },
    {
      label: "Industry Type",
      // value: { label: "", value: "" },
      required: true,
      value: "",
      type: "dropDown",
      options: [],
      ID: "type",
      table: "contact",
      emptyFlag: false,
      MOBILE_INDEX: 2,
      WEB_INDEX: 1,
    },
    // {
    //   label: "Brand",
    //   value: "",
    //   required: false,

    //   type: "input",
    //   ID: "brand",
    //   table: "contact",
    //   emptyFlag: "NM",
    //   MOBILE_INDEX: 3,
    //   WEB_INDEX: 7,
    // },
    {
      label: "Industry",
      required: true,

      // value: { label: "", value: "" },
      value: "",
      type: "dropDown",
      options: [],
      ID: "industry",
      table: "contact",
      emptyFlag: false,
      MOBILE_INDEX: 4,
      WEB_INDEX: 4,
    },
    {
      label: "Company (Select Other to enter)",
      // value: { label: "", value: "" },
      value: "",
      required: true,

      type: "dropDown",
      options: [],
      // options: [
      //   { label: "Tata Power", value: "Tata Power" },
      //   { label: "Tata Motor", value: "Tata Motor" },
      //   { label: "HewLett Packard", value: "HewLett Packard" },
      // ],
      ID: "company",
      table: "contact",
      emptyFlag: false,
      MOBILE_INDEX: 5,
      WEB_INDEX: 7,
    },
    {
      label: "Company Address",
      value: "",
      required: false,
      type: "input",
      ID: "address",
      table: "contact",
      emptyFlag: "NM",
      MOBILE_INDEX: 6,
      WEB_INDEX: 10,
    },
    {
      label: "City",
      required: true,
      // value: { label: "", value: "" },
      value: "",
      type: "dropDown",
      options: [
        { label: "Mumbai", value: "Mumbai" },
        { label: "Delhi", value: "Delhi" },
        { label: "Bangalore", value: "Bangalore" },
        { label: "Hyderabad", value: "Hyderabad" },
        { label: "Chennai", value: "Chennai" },
        { label: "Vadodara", value: "Vadodara" },
        { label: "Kolkata", value: "Kolkata" },
      ],
      ID: "city",
      table: "contact",
      emptyFlag: false,
      menuPlacement: "top",
      MOBILE_INDEX: 7,
      WEB_INDEX: 13,
    },

    // {
    //   label: "Meeting By",
    //   required: true,
    //   value: "",
    //   type: "dropDown",
    //   options: [],
    //   ID: "meetingBy",
    //   table: "meeting",
    //   emptyFlag: false,
    //   menuPlacement: "top",
    //   MOBILE_INDEX: 16,
    //   WEB_INDEX: 18,
    // },
    {
      label: "Contact Name",
      value: "",
      required: true,
      type: "input",
      ID: "name0",
      table: "contact",
      emptyFlag: false,
      MOBILE_INDEX: 8,
      WEB_INDEX: 2,
    },
    {
      label: "Designation",
      value: "",
      required: true,
      type: "input",
      ID: "designation",
      table: "contact",
      emptyFlag: false,
      MOBILE_INDEX: 9,
      WEB_INDEX: 5,
    },
    {
      label: "Email",
      required: true,

      value: "",
      type: "input",
      ID: "email",
      emptyFlag: false,
      table: "contact",
      valid: true,
      MOBILE_INDEX: 10,
      WEB_INDEX: 8,
    },
    {
      label: "Mobile",
      required: true,

      value: "",
      type: "input",
      ID: "mobile",
      emptyFlag: false,
      table: "contact",
      valid: true,
      MOBILE_INDEX: 11,
      WEB_INDEX: 11,
    },
    {
      label: "Landline Number",
      value: "",
      required: false,

      type: "input",
      ID: "landline",
      table: "contact",
      emptyFlag: "NM",
      MOBILE_INDEX: 12,
      WEB_INDEX: 14,
    },
    {
      label: "Level",
      required: true,
      // value: { label: "", value: "" },
      value: "",
      type: "dropDown",
      options: [
        { label: "CXO", value: "CXO" },
        { label: "Senior Mgmt", value: "Senior Mgmt" },
        { label: "Mid Mgmt", value: "Mid Mgmt" },
        { label: "General", value: "General" },
      ],
      ID: "level",
      table: "contact",
      emptyFlag: false,
      // menuPlacement: "top",
      MOBILE_INDEX: 13,
      WEB_INDEX: 3,
    },
    {
      label: "LinkedIn url",
      value: "",
      required: false,
      type: "input",
      ID: "linkedIn",
      table: "contact",
      emptyFlag: "NM",
      MOBILE_INDEX: 18,
      WEB_INDEX: 6,
    },

    // {
    //   label: "Date of Meeting",
    //   value: "",
    //   required: true,
    //   type: "input",
    //   ID: "meetingDate",
    //   table: "meeting",
    //   emptyFlag: false,
    //   MOBILE_INDEX: 14,
    //   WEB_INDEX: 3,
    // },

    // {
    //   label: "Meeting Time",
    //   value: "",
    //   required: true,
    //   type: "input",
    //   ID: "meetingTime",
    //   table: "meeting",
    //   emptyFlag: false,
    //   MOBILE_INDEX: 14,
    //   WEB_INDEX: 6,
    // },
    // {
    //   label: "Meeting Mode",
    //   value: "",
    //   required: true,
    //   ID: "meetingMode",
    //   table: "contact",
    //   emptyFlag: false,
    //   MOBILE_INDEX: 14,
    //   WEB_INDEX: 3,
    //   type: "dropDown",
    //   options: meetingMode,
    // },

    // {
    //   label: "Stage of engagement",
    //   required: true,
    //   // value: { label: "", value: "" },
    //   value: "",
    //   type: "dropDown",
    //   options: engagementStatus,
    //   ID: "engagementStatus",
    //   table: "contact",
    //   emptyFlag: false,
    //   // menuPlacement: "top",
    //   MOBILE_INDEX: 13,
    //   WEB_INDEX: 6,
    // },

    {
      label: "CR Manager",
      value: "",
      required: true,
      // value: "",
      type: "dropDown",
      ID: "crManager",
      table: "contact",
      options: [],
      emptyFlag: false,
      MOBILE_INDEX: 15,
      WEB_INDEX: 9,
    },
    // {
    //   label: "Meeting By",
    //   required: true,
    //   value: "",
    //   type: "dropDown",
    //   options: [],
    //   ID: "meetingBy",
    //   table: "meeting",
    //   emptyFlag: false,
    //   menuPlacement: "top",
    //   MOBILE_INDEX: 16,
    //   WEB_INDEX: 18,
    // },

    {
      label: "CRM Department",
      required: true,
      value: "",
      type: "dropDown",
      options: departmentOptions,
      ID: "department",
      table: "contact",
      emptyFlag: false,
      MOBILE_INDEX: 16,
      WEB_INDEX: 12,
    },
    // { emptyFlag: "NM", WEB_INDEX: 15, MOBILE_INDEX: 15 },
    // { emptyFlag: "NM", WEB_INDEX: 18, MOBILE_INDEX: 18 },

    // {
    //   label: "Remarks",
    //   value: "",
    //   required: false,

    //   type: "input",
    //   ID: "remarks",
    //   table: "meeting",
    //   emptyFlag: "NM",
    //   MOBILE_INDEX: 17,
    //   WEB_INDEX: 24,
    // },

    // {
    //   type: "blank",
    //   table: "na",
    //   ID: "BLANK",
    //   emptyFlag: "NM",
    //   MOBILE_INDEX: 19,
    //   WEB_INDEX: 18,
    // },

    // {
    //   label: "City *",
    //   value: "",
    //   type: "input",
    //   ID: "city",
    //   table: "contact",
    //   emptyFlag: false,
    // },
    // { emptyFlag: "NM", WEB_INDEX: 20, MOBILE_INDEX: 20 },
  ]);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (Number(windowSize.width) < 600) {
      // setMobileView(true);
      filterOnMobile();
      // setCurrentCrManager();
      // setCurrentCrManager();
    } else {
      filterOnWeb();
      // setCurrentCrManager();
      // setMobileView(false);
    }
  }, [
    windowSize.width,
    props.auth_name,
    props.employee_data,
    props.auth_email_id,
    props.auth_id,
  ]);

  const filterOnMobile = () => {
    let tempInputData = [...inputData];
    tempInputData.sort((a, b) => a.MOBILE_INDEX - b.MOBILE_INDEX);

    const crManagerIndex = tempInputData.findIndex(
      (item) => item.ID == "crManager"
    );

    if (crManagerIndex) {
      tempInputData[crManagerIndex].options = props.employee_data;
      tempInputData[crManagerIndex].value = {
        label: props.auth_name,
        value: props.auth_email_id,
        _id: props.auth_id,
      };
    }

    // let leasingMemberIndex = tempInputData.findIndex(
    //   (val) => val.ID == "crManager"
    // );
    // tempInputData[leasingMemberIndex].value = props.auth_name;

    setInputData(tempInputData);
  };
  const filterOnWeb = () => {
    let tempInputData = [...inputData];

    tempInputData.sort((a, b) => a.WEB_INDEX - b.WEB_INDEX);
    const crManagerIndex = tempInputData.findIndex(
      (item) => item.ID == "crManager"
    );

    if (crManagerIndex) {
      tempInputData[crManagerIndex].options = props.employee_data;
      tempInputData[crManagerIndex].value = {
        label: props.auth_name,
        value: props.auth_email_id,
        _id: props.auth_id,
      };
    }
    // let leasingMemberIndex = tempInputData.findIndex(
    //   (val) => val.ID == "crManager"
    // );
    // tempInputData[leasingMemberIndex].value = props.auth_name;

    setInputData(tempInputData);
  };

  // );)

  // get dropdown options
  useEffect(() => {
    let temp = [...inputData];

    if (inputList?.length > 0) {
      temp.map((val) => {
        val.value = inputList?.find((x) => x.ID == val.ID).value;
        if (val.ID == "type" && val.value == undefined) {
          val.value = "";
        }
      });
    }
    let tempDd = [...props.dd_options];
    tempDd.sort(function (a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    temp.map((val) => {
      if (
        val.ID != "level" &&
        val.ID != "company" &&
        val.ID != "meetingMode" &&
        val.ID != "engagementStatus" &&
        val.ID != "crManager"
      ) {
        let ddOption = [];
        tempDd.map((inVal) => {
          if (val.ID == inVal.dropdownId && val.ID != "industry") {
            ddOption.push({ label: inVal.label, value: inVal.value });
          }
        });
        val.options = ddOption;
      }
    });
    if (temp.length > 0 && temp.find((x) => x.ID == "type").value != "") {
      let indindex = temp.findIndex((x) => x.ID == "industry");
      let indData = tempDd.filter(
        (val) =>
          val.typeForIndustry == temp.find((x) => x.ID == "type")?.value?.label
      );
      temp[indindex].options = indData;
      setIndustryList(temp[indindex]);
    }
    let tempType = temp.find((x) => x.ID == "type");
    tempType.options = props.dd_options
      .filter((val) => val.dropdownId == "type")
      .sort(function (a, b) {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    tempType.value = typeData?.value;
    setTypeData(tempType);
    temp.map((val) => {
      if (val.ID != "crManager") {
        val.value = "";
      }
    });
    setInputList(temp);
  }, [props, inputData]);

  //-------------------------------------

  const dateToUTCConvert = (date) => {
    var date = new Date(date);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    let thisDate = new Date(now_utc);
    let theDate = thisDate.getDate().toString();
    if (Number(theDate) < 10) {
      theDate = "0" + theDate;
    }
    let theMonth = (thisDate.getMonth() + 1).toString();
    if (Number(theMonth) < 10) {
      theMonth = "0" + theMonth;
    }
    let theYear = thisDate.getFullYear().toString();
    return theYear + "/" + theMonth + "/" + theDate;
  };

  const removeN = (string) => {
    let newString = string.replace(/\n/g, "");
    return newString;
  };
  const removeSpecialChars = (string) => {
    if (string) {
      let newString = string.replace(/[=!@#$%^&*(),.?":{}|<>]/g, "");
      return newString.trim();
    } else return "";
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleImageUpload = async (event) => {
    try {
      let ocrEmail;
      setLoading(true);
      var file;
      if (isCamera) {
        file = dataURLtoFile(event, "image.png");
      } else {
        file = event.target.files[0];
      }
      // const file = isCamera ? event : event.target.files[0];

      if (file && file["type"].split("/")[0] === "image") {
        let formData = new FormData();
        formData.append("contactFile", file);
        axios
          .post(AxiosDefaultPort + "apis/ScanCard/ScanCard", formData)
          .then((res) => {
            toast.success("Contact Scanned");
            setLoading(false);
            let tempData = [...inputList];
            tempData.map((val) => {
              Object.keys(res.data).forEach(function (key, index) {
                if (key == val.ID) {
                  if (val.type == "input") {
                    val.value = removeN(res.data[key]);
                  } else if (val.type == "dropDown") {
                    val.value = {
                      label: res.data[key],
                      value: res.data[key],
                    };
                  }
                }
              });
            });

            setInputList(tempData);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);

            toast.error("Something went wrong");
            console.log(err);
          });
        setScanModal(false);
      } else {
        setScanModal(true);
        setLoading(false);
        //warn
        toast.error("Please upload Image file only");
        // setScanModal(false);
      }

      // setScannedText(data.text);
    } catch (e) {
      setLoading(false);

      toast.error("This card could not be scanned");
    }
  };

  const crypt = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
      .split("")
      .map((c) => textToChars(c)[0]) // Convert each character to its char code
      .map(applySaltToChar) // Apply XOR with salt
      .map(byteHex) // Convert to hex
      .join(""); // Join all hex strings
  };
  const submitContact = () => {
    setLoading(true);
    let enEmail = crypt(mySalt, props.auth_email_id);

    let temp = [...inputList];
    if (temp.some((val) => val.value == "" && val.emptyFlag != "NM")) {
      setLoading(false);
      temp.map((val) => {
        if (val.value == "" && val.emptyFlag != "NM") {
          val.emptyFlag = true;
        }
      });

      setInputList(temp);
    } else if (
      props.contact_data.some(
        (val) =>
          val.email == inputList.find((val) => val.ID == "email").value &&
          val.mobile == inputList.find((val) => val.ID == "mobile").value
      )
    ) {
      //warn
      setLoading(false);
      toast.error("This contact already exists");
    } else if (inputList.find((val) => val.ID == "mobile").valid != true) {
      //warn
      setLoading(false);
      toast.error("Please enter a valid mobile number");
    } else if (inputList.find((val) => val.ID == "email").valid != true) {
      //warn
      setLoading(false);
      toast.error("Please enter a valid email address");
    } else {
      let contactPayload = {};
      let meetingPayload = {};
      temp.map((val) => {
        if (val.table == "contact") {
          if (val.type == "input") {
            contactPayload[val.ID] = val.value;
          } else if (val.type == "dropDown") {
            if (val.ID == "crManager") {
              contactPayload[val.ID] = val.value._id;
            } else {
              contactPayload[val.ID] = val.value.label;
            }
          }
        } else if (val.table == "meeting") {
          if (val.ID == "meetingDate") {
            // let date = new Date(val.value);
            meetingPayload[val.ID] = dateToUTCConvert(val.value);
          } else {
            if (val.type == "input") {
              meetingPayload[val.ID] = val.value;
            } else if (val.type == "dropDown") {
              meetingPayload[val.ID] = val.value.label;
            }
          }
        }
      });
      contactPayload.isRemoved = "No";

      const postSharepointData = (token) => {
        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/contactCreate`, {
            contactPayload,
            token,
            code: enEmail,
          })
          .then((res1) => {
            // -------------
            if (res1.data.INVALID_CHARS == true) {
              setLoading(false);
              toast.error("Special characters not allowed in any input");
            } else {
              axios
                .post(
                  `${AxiosDefaultPort}apis/sharepoint/contactDataGet?token=${token}`,
                  {
                    AUTH_ID: props.auth_id,
                  }
                )
                .then((response) => {
                  toast.success("Contact Created");
                  let temp = [...response.data.value];

                  temp = temp.filter((val) => val.isRemoved == "No");
                  temp.map((val) => {
                    val.checked = false;
                  });
                  dispatch({
                    type: "CONTACT_DATA_GET",
                    payload: temp,
                  });
                  let cData = [...temp];

                  let nameInput = inputList.find(
                    (val) => val.ID == "name0"
                  ).value;
                  let emailInput = inputList.find(
                    (val) => val.ID == "email"
                  ).value;
                  let mobileInput = inputList.find(
                    (val) => val.ID == "mobile"
                  ).value;
                  let thisContact = cData.find(
                    (val) =>
                      val.name0 == nameInput &&
                      val.email == emailInput &&
                      val.mobile == mobileInput
                  );
                  meetingPayload.contactID = thisContact.ID.toString();

                  const [hours, minutes] =
                    meetingPayload.meetingTime.split(":");
                  let dateTime = new Date(meetingPayload?.meetingDate);
                  dateTime = dateTime.setHours(hours, minutes);
                  dateTime = new Date(dateTime);

                  meetingPayload.meetingTime = dateTime;
                })
                .catch((err) => {
                  setLoading(false);
                });
            }
            // -------------
          })
          .catch((err) => {
            setLoading(false);
          });
      };
      getAccessToken(postSharepointData);
    }
  };
  const deleteDdOption = (item) => {
    const deleteApi = (token) => {
      // const url = `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('dropdownOptions')/items/getbyid('${item.value}')`;

      // axios
      //   .delete(
      //     url,

      //     {
      //       headers: {
      //         accept: "application/json;odata=verbose",
      //         "content-type": "application/json;odata=verbose",
      //         "If-Match": "*",
      //         Authorization: "Bearer " + token,
      //       },
      //     }
      //   )
      axios
        .post(`${AxiosDefaultPort}apis/sharepoint/ddOptionDelete`, {
          token,
          deleteID: item.value,
        })
        .then((response) => {
          toast.success("Dropdown option deleted");
          let tempDdOptions = [...props.dd_options];
          let optionAfterDelete = [];
          if (props.dd_options != undefined) {
            optionAfterDelete = tempDdOptions.filter(
              (option) => option.ID != item.value
            );
          }
          dispatch({
            type: "DROPDOWN_OPTIONS_GET",
            payload: optionAfterDelete,
          });
          // -----------
          let indData = optionAfterDelete.filter(
            (val) => val.typeForIndustry == typeData.value.label
          );
          indData.sort(function (a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          let tempInd = { ...industryList };
          tempInd.options = indData;
          setIndustryList(tempInd);
          // -----------
        });
    };
    getAccessToken(deleteApi);
  };
  const addOptionHandler = (val) => {
    if (addOptionLabel == "") {
      toast.error("Please fill option");
    } else {
      setAddOptionFlag(false);
      let optionPayload = { dropdownId: val.ID, label: addOptionLabel };
      if (val.ID == "industry") {
        optionPayload.typeForIndustry = typeData?.value?.label;
      }
      const postOptionData = (token) => {
        // axios
        //   .post(
        //     `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('dropdownOptions')/items`,
        //     {
        //       __metadata: { type: "SP.Data.DropdownOptionsListItem" },
        //       // designation: "asdas",
        //       ...optionPayload,
        //     },
        //     {
        //       headers: {
        //         "Content-Type": "application/json;odata=verbose",
        //         Accept: "application/json;odata=verbose",
        //         Authorization: "Bearer " + token,
        //       },
        //     }
        //   )
        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/ddOptionCreate`, {
            optionPayload,
            token,
          })
          .then((response) => {
            // -------------
            setAddOptionLabel("");
            setAddOptionFlag(false);
            if (response.data.INVALID_CHARS == true) {
              toast.error("Special characters not allowed");
            } else {
              toast.success("Option added");
              axios
                .post(`${AxiosDefaultPort}apis/sharepoint/ddOptionsGet`, {
                  token,
                })
                .then((resp) => {
                  let optionRes = [...resp.data.value];
                  optionRes.map((val) => {
                    val.value = val.ID;
                  });
                  let copyRes = [...optionRes];
                  dispatch({
                    type: "DROPDOWN_OPTIONS_GET",
                    payload: optionRes,
                  });
                  // ---------------------------
                  let indData = optionRes.filter(
                    (val) => val.typeForIndustry == typeData.value?.label
                  );
                  indData.sort(function (a, b) {
                    var textA = a.label.toUpperCase();
                    var textB = b.label.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  });
                  let tempInd = { ...industryList };
                  tempInd.options = indData;
                  setIndustryList(tempInd);
                  // ---------------
                });
            }
          });
      };
      getAccessToken(postOptionData);
    }
  };

  const [importFieldData, setImportFieldData] = useState([]);
  const [ImportModalOpen, setImportModalOpen] = useState(false);
  const handleImportModalOpen = () => setImportModalOpen(true);
  const handleImportModalClose = () => setImportModalOpen(false);

  const DownloadImportFormat = () => {
    const csvContent = [headers.join(",")].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "contact-create-format.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImportColumnChange = (key, index, value) => {
    let tempImportFieldData = [...importFieldData];
    tempImportFieldData[index][key] = value;
    setImportFieldData(tempImportFieldData);
  };
  const [editColumns, setEditColumn] = useState(false);

  const headers = [
    // "location",
    "type",
    // "brand",
    "industry",
    "company",
    "address",
    "city",
    "name0",
    "designation",
    "email",
    "mobile",
    "landline",
    "level",
    // "meetingDate",
    // "meetingTime",
    "crManagerEmail",
    "department",
    // "remarks",
    "linkedIn",
  ]; // Example headers
  const importColumns = [
    // {
    //   name: "Location",
    //   selector: (val) => val.location,
    //   sortable: false,
    //   cell: (val, index) => (
    //     <span>
    //       {editColumns ? (
    //         <input
    //           type="text"
    //           placeholder="Location"
    //           className="transparent-input"
    //           value={val.location}
    //           onChange={(e) => {
    //             handleImportColumnChange("location", index, e.target.value);
    //           }}
    //         />
    //       ) : (
    //         <span>{val.location}</span>
    //       )}
    //     </span>
    //   ),
    // },
    {
      name: "Type",
      selector: (val) => val.type,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Type"
              className="transparent-input"
              value={val.type}
              onChange={(e) => {
                handleImportColumnChange("type", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.type}</span>
          )}
        </span>
      ),
    },
    // {
    //   name: "Brand",
    //   selector: (val) => val.brand,
    //   sortable: false,
    //   cell: (val, index) => (
    //     <span>
    //       {editColumns ? (
    //         <input
    //           type="text"
    //           placeholder="Brand"
    //           className="transparent-input"
    //           value={val.brand}
    //           onChange={(e) => {
    //             handleImportColumnChange("brand", index, e.target.value);
    //           }}
    //         />
    //       ) : (
    //         <span>{val.brand}</span>
    //       )}
    //     </span>
    //   ),
    // },
    {
      name: "Industry",
      selector: (val) => val.industry,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Industry"
              className="transparent-input"
              value={val.industry}
              onChange={(e) => {
                handleImportColumnChange("industry", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.industry}</span>
          )}
        </span>
      ),
    },
    {
      name: "Company",
      selector: (val) => val.company,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Company"
              className="transparent-input"
              value={val.company}
              onChange={(e) => {
                handleImportColumnChange("company", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.company}</span>
          )}
        </span>
      ),
    },
    {
      name: "Company Address",
      selector: (val) => val.address,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Company Address"
              className="transparent-input"
              value={val.address}
              onChange={(e) => {
                handleImportColumnChange("address", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.address}</span>
          )}
        </span>
      ),
    },
    {
      name: "City",
      selector: (val) => val.city,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="City"
              className="transparent-input"
              value={val.city}
              onChange={(e) => {
                handleImportColumnChange("city", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.city}</span>
          )}
        </span>
      ),
    },
    {
      name: "Contact Name",
      selector: (val) => val.name0,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Contact Name"
              className="transparent-input"
              value={val.name0}
              onChange={(e) => {
                handleImportColumnChange("name0", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.name0}</span>
          )}
        </span>
      ),
    },
    {
      name: "Landline",
      selector: (val) => val.landline,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Landline"
              className="transparent-input"
              value={val.landline}
              onChange={(e) => {
                handleImportColumnChange("landline", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.landline}</span>
          )}
        </span>
      ),
    },
    {
      name: "Level",
      selector: (val) => val.level,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Level"
              className="transparent-input"
              value={val.level}
              onChange={(e) => {
                handleImportColumnChange("level", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.level}</span>
          )}
        </span>
      ),
    },
    // {
    //   name: "Date Of meeting",
    //   selector: (val) => val.DATE_OF_MEETING,
    //   sortable: false,
    //   cell: (val, index) => (
    //     <span>
    //       {editColumns ? (
    //         <input
    //           type="text"
    //           placeholder="Date Of meeting"
    //           className="transparent-input"
    //           value={val.DATE_OF_MEETING}
    //           onChange={(e) => {
    //             handleImportColumnChange(
    //               "DATE_OF_MEETING",
    //               index,
    //               e.target.value
    //             );
    //           }}
    //         />
    //       ) : (
    //         <span>{val.DATE_OF_MEETING}</span>
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   name: "Meeting Time",
    //   selector: (val) => val.MEETING_TIME,
    //   sortable: false,
    //   cell: (val, index) => (
    //     <span>
    //       {editColumns ? (
    //         <input
    //           type="text"
    //           placeholder="Date Of meeting"
    //           className="transparent-input"
    //           value={val.MEETING_TIME}
    //           onChange={(e) => {
    //             handleImportColumnChange("MEETING_TIME", index, e.target.value);
    //           }}
    //         />
    //       ) : (
    //         <span>{val.MEETING_TIME}</span>
    //       )}
    //     </span>
    //   ),
    // },
    {
      name: "CR Manager",
      selector: (val) => val.crManagerEmail,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Data Entered By"
              className="transparent-input"
              value={val.crManagerEmail}
              onChange={(e) => {
                handleImportColumnChange(
                  "crManagerEmail",
                  index,
                  e.target.value
                );
              }}
            />
          ) : (
            <span>{val.crManagerEmail}</span>
          )}
        </span>
      ),
    },
    {
      name: "Department",
      selector: (val) => val.department,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="Department"
              className="transparent-input"
              value={val.department}
              onChange={(e) => {
                handleImportColumnChange("department", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.department}</span>
          )}
        </span>
      ),
    },
    // {
    //   name: "Remarks",
    //   selector: (val) => val.remarks,
    //   sortable: false,
    //   cell: (val, index) => (
    //     <span>
    //       {editColumns ? (
    //         <input
    //           type="text"
    //           placeholder="Remarks"
    //           className="transparent-input"
    //           value={val.remarks}
    //           onChange={(e) => {
    //             handleImportColumnChange("remarks", index, e.target.value);
    //           }}
    //         />
    //       ) : (
    //         <span>{val.remarks}</span>
    //       )}
    //     </span>
    //   ),
    // },
    {
      name: "LinkedIn",
      selector: (val) => val.linkedIn,
      sortable: false,
      cell: (val, index) => (
        <span>
          {editColumns ? (
            <input
              type="text"
              placeholder="LinkedIn"
              className="transparent-input"
              value={val.REMARKS}
              onChange={(e) => {
                handleImportColumnChange("linkedIn", index, e.target.value);
              }}
            />
          ) : (
            <span>{val.linkedIn}</span>
          )}
        </span>
      ),
    },
  ];
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    if (file) {
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        // Assume the first sheet is the one you want to read
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the sheet to JSON format (header row is considered as row 1)
        const data = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // Check if the headers match the expected format
        const fileHeaders = data[0]; // Get the first row, which should be the header

        // Compare headers with the expected headers
        const headersMatch =
          JSON.stringify(fileHeaders) === JSON.stringify(headers);

        // Check if there is at least one data row (i.e., data array length > 1)
        const hasDataRow = data.length > 1;

        if (headersMatch && hasDataRow) {
          // Transform data to array of objects
          const formattedData = data.slice(1).map((row) => {
            return headers.reduce((obj, header, index) => {
              // Set an empty string if no data is present in the cell
              obj[header] = row[index] !== undefined ? row[index] : "";
              return obj;
            }, {});
          });

          // Set the data to state

          setImportFieldData(formattedData);

          handleImportModalOpen();
        } else {
          // If headers do not match or no data row is present, alert the user
          let errorMessage = "";
          if (!headersMatch)
            errorMessage += "Headers do not match the expected format.";
          if (!hasDataRow) errorMessage += " There is no data row in the file.";
          toast.error(errorMessage);
        }
      };

      reader.readAsBinaryString(file);
    }
    // Define the expected headers
  };

  const handleContactsImport = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.uploadContacts, {
        contact_data: importFieldData,
        auth_id: props.auth_id,
      })
      .then((response) => {
        props.ContactDataGetAction(props.auth_id);
        toast.success("Data upload successful");
        handleImportModalClose();
        setImportFieldData([]);
      })
      .catch((err) => {
        toast.error("Occurred error while uploading data");
      });
  };
  return (
    <Box className="dashboard-main-container">
      <div>
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
          gutter={24}
          toastOptions={{
            style: {
              border: "1px solid #grey",
              padding: "16px",
            },
            success: {
              style: {
                background: "#c4fcb1",
                opacity: 0.1,
              },
            },
          }}
        />
      </div>
      <Navbar headText="CONTACT_CAPTURE" />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          {/* <h9 className="header-text">Contact Capture</h9> */}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Add Contact{" "}
          </Typography>
        </Box>

        {/* <CustomButton
          Label="Scan Card"
          buttonColor="#3871a8"
          // buttonColor="#40b3a2"
          style={{
            // cursor: "pointer",
            width: 100,
            height: 30,
            borderRadius: 5,
            marginRight: 50,
          }}
          labelStyle={{
            color: "#fff",
            fontSize: 15,
          }}
          onClick={() => {
            setScanProcessModal(true);
            setIsCamera(false);
          }}
        /> */}
        <div
          style={{
            display: "flex",
          }}
        >
          <button
            class="Documents-btn"
            onClick={() => {
              setScanProcessModal(true);
              setIsCamera(false);
            }}
          >
            <span class="folderContainer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 146 113"
                height="113"
                width="146"
                class="fileBack"
              >
                <path
                  fill="url(#paint0_linear_117_4)"
                  d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
                ></path>
                <defs>
                  <linearGradient
                    gradientUnits="userSpaceOnUse"
                    y2="95.4804"
                    x2="72.93"
                    y1="0"
                    x1="0"
                    id="paint0_linear_117_4"
                  >
                    <stop stop-color="#8F88C2"></stop>
                    <stop stop-color="#5C52A2" offset="1"></stop>
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 88 99"
                height="99"
                width="88"
                class="filePage"
              >
                <rect
                  fill="url(#paint0_linear_117_6)"
                  height="99"
                  width="88"
                ></rect>
                <defs>
                  <linearGradient
                    gradientUnits="userSpaceOnUse"
                    y2="160.5"
                    x2="81"
                    y1="0"
                    x1="0"
                    id="paint0_linear_117_6"
                  >
                    <stop stop-color="white"></stop>
                    <stop stop-color="#686868" offset="1"></stop>
                  </linearGradient>
                </defs>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 160 79"
                height="79"
                width="160"
                class="fileFront"
              >
                <path
                  fill="url(#paint0_linear_117_5)"
                  d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
                ></path>
                <defs>
                  <linearGradient
                    gradientUnits="userSpaceOnUse"
                    y2="82.8317"
                    x2="66.9106"
                    y1="8.71323"
                    x1="38.7619"
                    id="paint0_linear_117_5"
                  >
                    <stop stop-color="#C3BBFF"></stop>
                    <stop stop-color="#51469A" offset="1"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <p class="text">Scan Card</p>
          </button>
        </div>
      </Box>
      <div className="upload-export-container">
        <label
          htmlFor="upload"
          className="import-button"
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            marginRight: 10,

            // marginRight: 5,
            // marginLeft: 5,

            minWidth: 150,
            backgroundColor: "#23a466",
          }}
        >
          Upload{" "}
          <img
            src={UploadImg}
            style={{
              width: 30,
              height: 30,
              marginLeft: 5,
            }}
          />
        </label>
        <input
          type="file"
          name="upload"
          className="form-control"
          hidden={true}
          // multiple
          id="upload"
          accept=".csv, .xlsx, .xls"
          style={{
            backgroundColor: "#23a466",
          }}
          onChange={(e) => {
            handleFileUpload(e);
            e.target.value = null;
            // const fileArray = Array.from(fileList);
            // setSelectedFiles((prevState) => [...prevState, ...fileArray]);
          }}
        />

        <button
          style={{
            marginRight: 10,
            minWidth: 150,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            DownloadImportFormat();
          }}
          className="signup-button"
        >
          Excel format
          <img
            src={downloadImg}
            style={{
              width: 30,
              height: 30,
              marginLeft: 5,
            }}
          />
        </button>
      </div>
      <Box className="grid-container">
        {inputList?.map((val, index) => {
          if (val.type == "dropDown") {
          }

          return (
            <div>
              <Box className="grid-item">
                {((val.type != "checkBox" && val.ID != "company") ||
                  (val.ID == "company" && val.type == "dropDown")) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        // color: "#284b8c",
                        color: COLORS.gray40,
                        fontWeight: "bold",
                        marginBottom: 0.5,
                        fontSize: 14,
                        mt: 1,
                      }}
                    >
                      {val.label}{" "}
                      {val?.required && (
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                    {val.type == "dropDown" &&
                      val.ID != "level" &&
                      val.ID != "company" &&
                      val.ID != "meetingMode" &&
                      val.ID != "engagementStatus" &&
                      val.ID != "crManager" &&
                      empData != undefined &&
                      empData.role == "admin" && (
                        <Tooltip title={"Edit Dropdown Options"}>
                          <Box
                            sx={{ ml: 2, mb: -1, cursor: "pointer" }}
                            onClick={() => {
                              let temp = { ...editDdModal };
                              temp[val.ID] = true;
                              setEditDdModal(temp);
                            }}
                          >
                            <IconContext.Provider
                              value={{ size: 17, color: COLORS.gray40 }}
                            >
                              <TbEdit onClick={() => {}} />
                            </IconContext.Provider>
                          </Box>
                        </Tooltip>
                      )}
                  </Box>
                )}

                {val.ID == "company" && val.type == "input" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        // color: "#284b8c",
                        color: COLORS.gray40,
                        fontWeight: "bold",
                        marginBottom: 0.5,
                        fontSize: 14,
                        mt: 1,
                        mr: 2,
                      }}
                    >
                      {val.label}
                    </Typography>
                    <IconContext.Provider
                      value={{ size: 20, color: "#bfbfbf" }}
                    >
                      <IoMdArrowDropdownCircle
                        onClick={() => {
                          let temp = [...inputList];
                          temp.find((val) => val.ID == "company").type =
                            "dropDown";
                          setInputList(temp);
                        }}
                      />
                    </IconContext.Provider>
                  </Box>
                )}

                {/* <TextField value={val.label}/> */}
                {val.ID == "meetingDate" && (
                  <Box className="group show-zindex" sx={{ display: "flex" }}>
                    <DatePicker
                      onKeyDown={(e) => e.preventDefault()}
                      selected={selectedDate}
                      onChange={(e) => {
                        setSelectedDate(e);
                        let dateStr = e.toLocaleString().substr(0, 10).trim();

                        dateStr = dateStr.replace(",", "").split("/");
                        if (Number(dateStr[0]) < 10) {
                          dateStr[0] = "0" + dateStr[0];
                        }
                        if (Number(dateStr[1]) < 10) {
                          dateStr[1] = "0" + dateStr[1];
                        }
                        let dateVal =
                          dateStr[2] + "-" + dateStr[1] + "-" + dateStr[0];
                        let temp = [...inputList];
                        temp[index].value = dateVal;
                        temp[index].emptyFlag = false;
                        setInputList(temp);
                      }}
                      dateFormat="dd/MM/yyyy"
                      className="form-control text-box-style show-zindex"
                      placeholderText="dd/mm/yyyy"
                    />
                  </Box>
                )}
                {val.ID == "meetingTime" && (
                  <Box className="" sx={{ display: "flex" }}>
                    <CustomInput
                      Type={"time"}
                      // Disabled={val.ID == "crManager" ? true : false}
                      error={val.emptyFlag}
                      Value={inputList[index].value}
                      onChange={(e) => {
                        // toast.success("error message");

                        let temp = [...inputList];
                        temp[index].value = e.target.value;

                        if (val.ID == "email") {
                          var regex = /\S+@\S+\.\S+/;
                          if (regex.test(e.target.value)) {
                            temp[index].valid = true;
                          } else {
                            temp[index].valid = false;
                          }
                        }

                        if (
                          temp[index].value == "" &&
                          temp[index].emptyFlag != "NM"
                        ) {
                          temp[index].emptyFlag = true;
                        } else if (temp[index].emptyFlag != "NM") {
                          temp[index].emptyFlag = false;
                        }
                        if (
                          temp[index].value &&
                          temp[index].emptyFlag != "NM"
                        ) {
                          temp[index].emptyFlag = false;
                        }
                        setInputList(temp);
                      }}
                    />
                  </Box>
                )}
                {val.type == "input" &&
                  val.ID != "mobile" &&
                  val.ID != "meetingTime" &&
                  val.ID != "meetingDate" && (
                    <Box sx={{ display: "flex" }}>
                      <CustomInput
                        Placeholder={val.label.split("*")[0]}
                        Type={val.ID == "meetingDate" ? "date" : "text"}
                        Disabled={val.ID == "crManager" ? true : false}
                        error={val.emptyFlag}
                        Value={inputList[index].value}
                        // Value={
                        //   val.ID == "meetingDate"
                        //     ? displayDate
                        //     : inputList[index].value
                        // }
                        onChange={(e) => {
                          // toast.success("error message");
                          let temp = [...inputList];
                          temp[index].value = e.target.value;

                          if (val.ID == "email") {
                            var regex = /\S+@\S+\.\S+/;
                            if (regex.test(e.target.value)) {
                              temp[index].valid = true;
                            } else {
                              temp[index].valid = false;
                            }
                          }

                          if (
                            temp[index].value == "" &&
                            temp[index].emptyFlag != "NM"
                          ) {
                            temp[index].emptyFlag = true;
                          } else if (temp[index].emptyFlag != "NM") {
                            temp[index].emptyFlag = false;
                          }
                          if (
                            temp[index].value &&
                            temp[index].emptyFlag != "NM"
                          ) {
                            temp[index].emptyFlag = false;
                          }
                          setInputList(temp);
                        }}
                      />
                    </Box>
                  )}
                {val.ID == "mobile" && (
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <CustomInput
                        error={val.emptyFlag}
                        Placeholder={val.label.split("*")[0]}
                        Value={inputList[index].value}
                        onChange={(e) => {
                          let temp = [...inputList];

                          let phone = e.target.value.replace(/[^0-9]/g, "");

                          if (e.target.value != "" && phone.length != 10) {
                            temp[index].valid = false;
                          } else {
                            temp[index].valid = true;
                          }
                          temp[index].value = phone;
                          if (temp[index].valid == false) {
                            temp[index].emptyFlag = false;
                          } else if (
                            temp[index].value == "" &&
                            temp[index].emptyFlag != "NM"
                          ) {
                            temp[index].emptyFlag = true;
                          } else if (temp[index].emptyFlag != "NM") {
                            temp[index].emptyFlag = false;
                          }
                          if (
                            temp[index].value &&
                            temp[index].emptyFlag != "NM"
                          ) {
                            temp[index].emptyFlag = false;
                          }
                          setInputList(temp);
                        }}
                      />
                    </Box>
                    {!val.valid && (
                      <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                        Please enter valid mobile number
                      </Typography>
                    )}
                  </Box>
                )}
                {val.type == "checkBox" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: COLORS.gray60,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {val.label}
                    </Typography>
                    <Checkbox
                      checked={val.value}
                      onChange={() => {
                        let temp = [...inputList];
                        temp[index].value = !temp[index].value;
                        setInputList(temp);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ color: COLORS.gray60 }}
                    />
                  </Box>
                )}
                {val.type == "dropDown" && (
                  <Box sx={{}}>
                    <CustomDropdown
                      menuPlacement={val?.menuPlacement}
                      Label={val.label}
                      Options={val.options}
                      Value={val.value}
                      error={val.emptyFlag}
                      OnChange={(e) => {
                        let temp = [...inputList];
                        temp[index].value = e;
                        if (
                          temp[index].value &&
                          temp[index].emptyFlag != "NM"
                        ) {
                          temp[index].emptyFlag = false;
                        } else if (temp[index].emptyFlag != "NM") {
                          temp[index].emptyFlag = true;
                        }
                        setInputList(temp);
                        if (val.ID == "company" && e.value == "other") {
                          let tempIn = [...inputList];
                          tempIn.find((val) => val.ID == "company").type =
                            "input";
                          tempIn.find((val) => val.ID == "company").value = "";
                          setInputList(tempIn);
                        }
                        if (val.ID == "company" && e.value != "other") {
                          let tempInput = [...inputList];
                          tempInput.find((val) => val.ID == "address").value =
                            props.contact_data.find(
                              (val) => val.company == e.value
                            ).address;
                          setInputList(tempInput);
                        }
                        if (val.ID == "type") {
                          let temp = [...inputList];
                          let allDdOptions = [...props.dd_options];

                          let indDdOptions = allDdOptions.filter(
                            (val) => val.typeForIndustry == e.label
                          );
                          let ind = temp.findIndex(
                            (val) => val.ID == "industry"
                          );
                          if (indDdOptions.length == 1) {
                            temp[ind].value = {
                              label: indDdOptions[0].label,
                              value: indDdOptions[0].value,
                            };

                            temp[ind].emptyFlag = false;
                          } else {
                            temp[ind].value = "";
                          }
                          indDdOptions.sort(function (a, b) {
                            var textA = a.label.toUpperCase();
                            var textB = b.label.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          });
                          temp[ind].options = indDdOptions;
                          let tempData = [...inputList];
                          tempData[index].value = e;
                          setTypeData(tempData[index]);
                          setIndustryList(temp[ind]);
                          setInputList(temp);
                        }
                      }}
                    />
                  </Box>
                )}

                {val.emptyFlag == true && (
                  <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                    Please fill {val.label}
                  </Typography>
                )}
                {val.ID == "email" && val.value != "" && val.valid == false && (
                  <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                    Please enter valid Email address
                  </Typography>
                )}
              </Box>
              <Modal
                open={editDdModal[val.ID]}
                onClose={() => {
                  let temp = { ...editDdModal };
                  temp[val.ID] = false;
                  setEditDdModal(temp);
                  setAddOptionFlag(false);
                  setAddOptionLabel("");
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="modal-box">
                  <Box className="dd-modal-box">
                    <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                      <RxCross1
                        onClick={() => {
                          let temp = { ...editDdModal };
                          temp[val.ID] = false;
                          setEditDdModal(temp);
                          setAddOptionFlag(false);
                          setAddOptionLabel("");
                        }}
                      />
                    </IconContext.Provider>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: COLORS.gray70,
                      ml: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Dropdown Options : {val.label}
                  </Typography>
                  <Box className="edit-line" />

                  <Box className="dd-options-modal">
                    {val.options != undefined && (
                      <Box>
                        {val.ID == "industry" && (
                          <>
                            <Box sx={{ my: 2 }}>
                              <CustomDropdown
                                menuPlacement={val?.menuPlacement}
                                Options={typeData.options}
                                Value={typeData.value}
                                error={val.emptyFlag}
                                OnChange={(e) => {
                                  let temp = { ...typeData };
                                  temp.value = e;
                                  setTypeData(temp);
                                  let indData = props.dd_options.filter(
                                    (val) => val.typeForIndustry == e.label
                                  );
                                  indData.sort(function (a, b) {
                                    var textA = a.label.toUpperCase();
                                    var textB = b.label.toUpperCase();
                                    return textA < textB
                                      ? -1
                                      : textA > textB
                                      ? 1
                                      : 0;
                                  });
                                  let tempInd = { ...industryList };
                                  tempInd.options = indData;
                                  setIndustryList(tempInd);
                                }}
                              />
                            </Box>
                            {industryList?.options?.map((item) => {
                              return (
                                <>
                                  <Box
                                    Box
                                    sx={{
                                      mt: 1,
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography>{item.label}</Typography>
                                    <Box
                                      onClick={() => deleteDdOption(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        // className="ln-img"
                                        style={{
                                          marginRight: 10,
                                          height: 15,
                                          width: 15,
                                          marginLeft: 10,
                                        }}
                                        src={Icons.del1}
                                        alt=""
                                        className="img-responsive"
                                        tintColor="#fff"
                                      />
                                    </Box>
                                  </Box>
                                  <Divider />
                                </>
                              );
                            })}
                          </>
                        )}
                        {val.ID != "industry" && (
                          <>
                            {val.options.map((item) => {
                              return (
                                <>
                                  <Box
                                    Box
                                    sx={{
                                      mt: 1,
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography>{item.label}</Typography>
                                    <Box
                                      onClick={() => deleteDdOption(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        // className="ln-img"
                                        style={{
                                          marginRight: 10,
                                          height: 15,
                                          width: 15,
                                          marginLeft: 10,
                                        }}
                                        src={Icons.del1}
                                        alt=""
                                        className="img-responsive"
                                        tintColor="#fff"
                                      />
                                    </Box>
                                  </Box>
                                  <Divider />
                                </>
                              );
                            })}
                          </>
                        )}
                      </Box>
                    )}
                    <Box
                      sx={{
                        padding: 2,
                        border: "1px solid grey",
                        borderRadius: 2,
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", color: COLORS.gray50 }}
                        >
                          Option Label
                        </Typography>
                        <CustomInput
                          Placeholder="Enter Dropdown Option"
                          Value={addOptionLabel}
                          onChange={(e) => {
                            setAddOptionLabel(e.target.value);
                          }}
                        />
                      </Box>

                      <Box sx={{ mt: 3 }}>
                        <CustomButton
                          Label="Add Option"
                          buttonColor="#3871a8"
                          style={{
                            height: 30,
                            borderRadius: 5,
                          }}
                          labelStyle={{
                            color: "#fff",
                            fontSize: 15,
                          }}
                          onClick={() => {
                            addOptionHandler(val);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
              {/* --------------- */}
            </div>
          );
        })}
      </Box>
      <Box className="btn-container">
        {/* <Box className="btn-inner-container"> */}

        {/* </Box> */}
        <CustomButton
          Label="Reset"
          // buttonColor="#3871a8"
          buttonColor="#d32f2f"
          style={{
            width: 100,
            height: 30,
            borderRadius: 5,
          }}
          labelStyle={{
            color: "#fff",
            fontSize: 15,
          }}
          onClick={() => {
            let temp = [...inputData];
            let tempDd = [...props.dd_options];
            tempDd.sort(function (a, b) {
              var textA = a.label.toUpperCase();
              var textB = b.label.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            temp.map((val) => {
              if (val.ID != undefined && val.ID != "BLANK") {
                val.emptyFlag = false;
              }
              if (
                val.ID != "level" &&
                val.ID != "company" &&
                val.ID != "meetingMode" &&
                val.ID != "engagementStatus"
              ) {
                let ddOption = [];
                tempDd.map((inVal) => {
                  if (val.ID == inVal.dropdownId && val.ID != "industry") {
                    ddOption.push({ label: inVal.label, value: inVal.value });
                  }
                });
                val.options = ddOption;
              } else if (val.ID == "company") {
                setCompanyDd();
              }
            });

            setInputList(temp);
            // setCompanyDd();
          }}
        />
        <CustomButton
          Label="Submit"
          // buttonColor="#3871a8"
          buttonColor="#23a466"
          style={{
            width: 100,
            height: 30,
            borderRadius: 5,
          }}
          labelStyle={{
            color: "#fff",
            fontSize: 15,
          }}
          onClick={submitContact}
        />
      </Box>
      <Modal
        open={scanModal}
        onClose={() => {
          setScanModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
      >
        <Box className="modal-box">
          <Box className="scan-modal">
            <input
              type="file"
              onChange={(e) => {
                setScanModal(false);
                setLoading(true);

                handleImageUpload(e);
              }}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={scanProcessModal}
        onClose={() => {
          setScanProcessModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <Box className="scan-process-modal">
            <CustomButton
              Label="Upload Image"
              buttonColor="#3871a8"
              style={{
                height: 30,
                borderRadius: 5,
              }}
              labelStyle={{
                color: "#fff",
                fontSize: 15,
              }}
              onClick={() => {
                setScanModal(true);
                setScanProcessModal(false);
              }}
            />
            <CustomButton
              Label="Scan card"
              buttonColor="#3871a8"
              // buttonColor="#40b3a2"
              style={{
                height: 30,
                borderRadius: 5,
              }}
              labelStyle={{
                color: "#fff",
                fontSize: 15,
              }}
              onClick={() => {
                setLoading(true);
                setOpenCamera(true);
                setScanProcessModal(false);
                setIsCamera(true);
              }}
            />
          </Box>
        </Box>
      </Modal>
      {openCamera && (
        <Box className="cam-modal-container">
          <Box className="cam-modal-box">
            <Typography
              sx={{ color: "#a12b2b", fontWeight: "bold", mr: 1 }}
              onClick={() => {
                setOpenCamera(false);
                setIsCamera(false);
              }}
            >
              Close
            </Typography>
            <IconContext.Provider value={{ size: 15, color: "#a12b2b" }}>
              <RxCross1
                onClick={() => {
                  setOpenCamera(false);
                }}
              />
            </IconContext.Provider>
          </Box>
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            onCameraStart={() => {
              setLoading(false);
            }}
            onCameraError={() => {
              setLoading(false);
              toast.error("Camera couldn't be opened");
            }}
            onTakePhoto={(dataUri) => {
              handleImageUpload(dataUri);
              setOpenCamera(false);
            }}
          />
        </Box>
      )}

      <Modal
        open={ImportModalOpen}
        onClose={handleImportModalClose}
        disableAutoFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={importStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Import Contacts</h4>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleImportModalClose}
            />
          </div>
          <div
            style={{
              height: 1,
              backgroundColor: "#d3d3d3",
              marginBottom: "2%",
            }}
          />

          <div style={{ position: "relative" }}>
            <>
              <span
                style={{
                  marginRight: 5,
                }}
              >
                Edit columns
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={editColumns}
                  onChange={(e) => {
                    setEditColumn(!editColumns);
                  }}
                />
                <span className="slider" />
              </label>
            </>
          </div>

          <ReactDataTable columns={importColumns} data={importFieldData} />

          <button
            onClick={(e) => {
              handleContactsImport();
            }}
            style={{
              // backgroundColor: "#219bcc",
              // marginTop: 15,
              padding: 10,
              minWidth: 140,
              textAlign: "center",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              // margin: "2%",
              // width: "30%",
            }}
            className="signup-button"
          >
            Import
          </button>
        </Box>
      </Modal>

      <Modal
        open={loading}
        // onClose={() => {
        //   setScanModal(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="loading-modal">
          <Lottie
            className="loading-lottie"
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      </Modal>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  meeting_data: state.contactData.meetingDataList,
  dd_options: state.contactData.ddOptionList,
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  // auth_email_id: "joyeeta.b@samishti.com",
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
  // auth_name: "Joyeeta",
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  DdOptionsGetAction,
  EmployeeDetailsGetAction,
})(ContactCapture);
