const defaultPort = "https://pdhanewala.com:9001/";
const userLogin = "cms/login/login";
const userLogout = "cms/login/logout";
const forgetPassword = "cms/forgetPassword/forgetPassword";
const verifyOtp = "cms/forgetPassword/verifyOtp";
const changePassword = "cms/forgetPassword/changePassword";
const uploadContacts = "cms/contacts/uploadContacts";
const sessionTokenVerify = "apis/Login/sessionTokenVerify";
const meetingFilter = "cms/meetings/meetingFilter";
const getMeetingUpdate = "cms/meetingUpdate/getMeetingUpdate";
const LeadStatusCrManagerWise = "cms/meetings/LeadStatusCrManagerWise";
const employeeUpdate = "apis/sharepoint/employeeUpdate";

export default {
  defaultPort,
  userLogin,
  forgetPassword,
  verifyOtp,
  changePassword,
  userLogout,
  uploadContacts,
  sessionTokenVerify,
  meetingFilter,
  getMeetingUpdate,
  LeadStatusCrManagerWise,
  employeeUpdate,
};
