"use client";
import {
  Grid,
  Box,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { useRef } from "react";
import "./LoginNew.css";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import { Card } from "@mui/material";

import { useDispatch } from "react-redux";
import CustomInput from "../components/CustomInput/CustomInput";
import { AxiosDefaultPort } from "../constants/Axios";
import axios from "../components/Axios/axios";
import { useNavigate } from "react-router-dom";
import images from "../assets/images";
import LoginInput from "../components/CustomInput/LoginInput";
import BETACODE_LOGO from "../assets/images/beta-code-logo.png";
import LoginBG from "../assets/images/loginBg.svg";
import Spinner from "../components/Spinner/Spinner";
import AXIOS from "../utils/AXIOS";
import { encodeData } from "../utils/Constants";

import TrueTalentLogo from "../assets/images/true-talent-logo.png";

const Login = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [CustomerPassword, setCustomerPassword] = useState("");
  const [CustomerPasswordErrorFlag, setCustomerPasswordErrorFlag] =
    useState("");
  const [CustomerEmailErrorFlag, setCustomerEmailErrorFlag] = useState(false);
  const [OtpInputFlag, setOtpInputFlag] = useState(false);
  const [OtpErrorFlag, setOtpErrorFlag] = useState(false);
  const [OtpInput, setOtpInput] = useState([]);
  const [TransactionStarted, setTransactionStarted] = useState(false);
  const [hostname, setHostname] = useState();
  const [ShowOTPForm, setShowOTPForm] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    let currentUrl = window.location.href;
    let currentHost = currentUrl.split("//")[1].split(".")[0];
    setHostname(currentHost);

    const SessionToken = window.localStorage.getItem("CMS_STOKEN");
    if (SessionToken) {
      handleSessionVerify(currentHost, SessionToken);
    }
  }, []);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleSessionVerify = (HostName, SessionID) => {
    axios
      .post(AXIOS.defaultPort + AXIOS.sessionTokenVerify, {
        HOSTNAME: HostName,
        SESSION_TOKEN: SessionID,
      })
      .then((response) => {
        if (response.otpMatch == true) {
          dispatch({
            type: "AUTH_DATA",
            auth_name: response.sessionData.NAME,
            auth_email_id: response.sessionData.EMAIL_ID,
            user_token: response.sessionData.SESSION_TOKEN,
            auth_id: response.sessionData.ID,
          });

          navigate("/dashboard");
        } else {
          window.localStorage.removeItem("CMS_STOKEN");
        }
      });
  };
  const HandleLogin = (e) => {
    e.preventDefault();
    let tempOtpInput = [...OtpInput];
    let otp = tempOtpInput.join("");
    otp = Number(otp);

    setTransactionStarted(true);
    if (OtpInputFlag) {
      if (!isNaN(Number(otp)) && OtpInput?.length == 6) {

        let encodedOTP = encodeData(otp);
        axios
          .post(AxiosDefaultPort + "apis/Login/otpVerify", {
            EMAIL_ID: CustomerEmail,
            HOSTNAME: hostname,
            OTP: encodedOTP,
          })
          .then((response) => {
            setTransactionStarted(false);
            setOtpInput("");
            if (response.otpMatch == true) {
              window.localStorage.setItem(
                "CMS_STOKEN",
                response.sessionData.SESSION_TOKEN
              );
              dispatch({
                type: "AUTH_DATA",
                auth_name: response.sessionData.NAME,
                auth_email_id: response.sessionData.EMAIL_ID,
                user_token: response.sessionData.SESSION_TOKEN,
                auth_id: response.sessionData.ID,
              });

              navigate("/dashboard");
            } else {
              toast.error("Wrong OTP");
            }
          })
          .catch((err) => {
            setOtpInput("");
            setTransactionStarted(false);
            toast.error("Something went wrong");
          });
      } else {
        setTransactionStarted(false);
        setOtpErrorFlag(true);
      }
    } else {
      if (CustomerEmail == "") {
        setCustomerEmailErrorFlag(true);
        setTransactionStarted(false);
      } else if (CustomerEmail != "") {
        axios
          .post(AxiosDefaultPort + "apis/Login/LoginOTPSend", {
            EMAIL_ID: CustomerEmail,
            IS_VERIFIED: false,
          })
          .then((response) => {
            setTransactionStarted(false);
            if (response.otpSent == true) {
              toast.success("OTP sent");
              setOtpInputFlag(true);
            } else {
              toast.error("Account not found!");
            }
          })
          .catch((err) => {
            setTransactionStarted(false);
            toast.error("Something went wrong");
          });
      }
    }
  };

  const handlePasswordLogin = (e) => {
    e.preventDefault();

    if (CustomerEmail == "") {
      toast.error("Please enter Email ID");
    } else if (CustomerPassword == "") {
      toast.error("Please enter password");
    } else {
      setTransactionStarted(true);

      let encodedPassword = encodeData(CustomerPassword);
      // encodedPassword = encodeData(encodedPassword);
      axios
        .post(AXIOS.defaultPort + AXIOS.userLogin, {
          USER_ID: CustomerEmail,
          PASSWORD: encodedPassword,
        })
        .then((response) => {

          if (response.PASSWORD_MATCHED == false) {
            setTransactionStarted(false);
            toast.error("Please provide valid ID and password");
          } else if (response?.NEW_USER == true) {
            handleForgotPasswordClick();
          } else {
            setTransactionStarted(false);
            window.localStorage.setItem("CMS_STOKEN", response.SESSION_TOKEN);
            dispatch({
              type: "AUTH_DATA",
              auth_name: response.NAME,
              auth_email_id: response.EMAIL_ID,
              user_token: response.SESSION_TOKEN,
              auth_id: response.ID,
            });

            navigate("/dashboard");
          }
        })
        .catch((err) => {
          setTransactionStarted(false);
          toast.error("Something went wrong");
        });
    }
  };
  const handleForgotPasswordClick = () => {
    if (CustomerEmail == "") {
      toast.error("Please enter user ID");
    } else {
      axios
        .post(AXIOS.defaultPort + AXIOS.forgetPassword, {
          USER_ID: CustomerEmail,
        })
        .then((response) => {

          if (response.useVerified == false) {
            toast.error("Unregistered user");
          } else {
            setShowOTPForm(true);
            toast.success("OTP sent to your email ID");
          }
          setTransactionStarted(false);
        })
        .catch((err) => {
          setTransactionStarted(false);
          console.log(err);

          toast.error("Something went wrong");
        });
    }
  };

  const verifyOtpHandler = (e) => {
    e.preventDefault();
    let otp = OtpInput.join("");
    setTransactionStarted(true);
    let encodedOTP = encodeData(otp);
  

    axios
      .post(AXIOS.defaultPort + AXIOS.verifyOtp, {
        USER_ID: CustomerEmail,
        OTP: encodedOTP,
      })
      .then((response) => {
        setTransactionStarted(false);

        if (response.OTPVerified == true) {
          toast.success("Correct OTP. Enter new password");
          setChangePassword(true);
          setShowOTPForm(null);
          setOtpInput([]);
        } else {
          toast.error("Incorrect OTP");
        }
      })
      .catch((err) => {
        setTransactionStarted(false);
        console.log("asdnasdsadas", err);

        toast.error("Something went wrong");
      });
  };
  const changePasswordHandler = (e) => {
    e.preventDefault();
    setTransactionStarted(true);
    let encodedPassword = encodeData(newPassword);
    axios
      .post(AXIOS.defaultPort + AXIOS.changePassword, {
        USER_ID: CustomerEmail,
        PASSWORD: encodedPassword,
      })
      .then((response) => {
        setTransactionStarted(false);
        toast.success("Password changed successfully");
        setChangePassword(false);
        setShowOTPForm(false);
      })
      .catch((err) => {
        setTransactionStarted(false);
        toast.error("Something went wrong");
      });
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    // If a letter is typed and the current input is not the last one
    if (value.length === 1 && index < inputRefs.length - 1) {
      // Focus on the next input
      inputRefs[index + 1].current.focus();
    }
    // If backspace is pressed and the currfail1ent input is not the first one
    else if (value.length === 0 && index > 0) {
      // Focus on the previous input
      inputRefs[index - 1].current.focus();
    }
    let temp = [...OtpInput];
    temp[index] = e.target.value;
    setOtpInput(temp);
  };

  const [PassLoginFlag, setPassLoginFlag] = useState(true);
  return (
    <div className="login-main-top-container">
      <div className="login-main-container">
        <Toaster />

        <Card
          className="sub-left-container"
          sx={{
            borderRadius: 5,
            marginLeft: 5,
          }}
        >
          <div className="">
            <img
              height={30}
              width={150}
              src={TrueTalentLogo}
              style={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className="login-samishti-logo">
            <img height={55} width={180} src={BETACODE_LOGO} />
          </div>

          <div className="login-left-inner-container">
            <h2 className="login-header-text raleway-font"> Hello! 👋</h2>
            <p
              className="sub-heading-text inter-font"
              style={{
                // fontWeight: "bold",
                color: "#677a8e",
                fontWeight: 500,
              }}
            >
              Login to your account.
            </p>
            {PassLoginFlag == false && (
              <form
                className="form-container"
                // onKeyDown={onKeyDownHandler}
                onSubmit={HandleLogin}
              >
                {OtpInputFlag && (
                  <div className="p-3">
                    <p
                      className="input-box-title "
                      style={{
                        // fontWeight: "bold",
                        color: "#677a8e",
                        fontWeight: 500,
                        fontSize: 13,
                        marginBottom: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {CustomerEmail}
                      <span
                        className="check-mark"
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    </p>
                    <p
                      className="input-box-title "
                      style={{
                        // fontWeight: "bold",
                        color: "#677a8e",
                        fontWeight: 500,
                        marginBottom: 10,
                      }}
                    >
                      OTP
                    </p>

                    {inputRefs.map((ref, index) => (
                      <input
                        key={index}
                        ref={ref}
                        maxLength={1}
                        // style={{
                        //   width: "30px",
                        //   height: "30px",
                        //   marginRight: "5px",
                        // }}
                        type="text"
                        minlength="1"
                        maxlength="1"
                        className="form-control otp-inputbar"
                        value={OtpInput[index]}
                        onChange={(e) => handleChange(index, e)}
                      />
                    ))}

                    {OtpErrorFlag && (
                      <p className="otp-error">Please enter a valid OTP</p>
                    )}
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                  <p className="call-again">
                    <span className="underlined">Send again</span>
                  </p>
                </div> */}
                  </div>
                )}
                {OtpInputFlag == false && (
                  <div className="p-3 m-1">
                    <p
                      className="input-box-title "
                      style={{
                        // fontWeight: "bold",
                        color: "#677a8e",
                        fontWeight: 500,
                        marginBottom: 10,
                      }}
                    >
                      Email ID
                    </p>
                    <LoginInput
                      Style={{ height: "7vh" }}
                      Type={"email"}
                      Placeholder={"Enter Email"}
                      value={CustomerEmail}
                      onChange={(e) => {
                        setCustomerEmail(e.target.value);
                        setCustomerEmailErrorFlag(false);
                      }}
                    />
                    {CustomerEmailErrorFlag && (
                      <span
                        className="input-box-title "
                        style={{
                          color: "red",
                        }}
                      >
                        Please enter email ID
                      </span>
                    )}
                  </div>
                )}
                <p
                  style={{
                    marginTop: 10,
                  }}
                  className="login-forgot-password-text"
                  onClick={() => {
                    setPassLoginFlag(true);
                  }}
                >
                  Login with password?
                </p>
                {OtpInputFlag && (
                  <button
                    onClick={HandleLogin}
                    className="login-signup-button"
                    type="submit"
                  >
                    <span className="span-1">
                      {TransactionStarted ? <Spinner /> : "Log In"}
                    </span>
                  </button>
                )}
                {!OtpInputFlag && (
                  <button
                    onClick={HandleLogin}
                    className="login-signup-button"
                    type="submit"
                  >
                    <span className="span-1">
                      {TransactionStarted ? <Spinner /> : "Send OTP"}
                    </span>
                  </button>
                )}
              </form>
            )}

            {PassLoginFlag == true && (
              <form
                className="form-container"
                // onKeyDown={onKeyDownHandler}
                onSubmit={handlePasswordLogin}
              >
                {OtpInputFlag && (
                  <div className="p-3">
                    <p
                      className="input-box-title "
                      style={{
                        // fontWeight: "bold",
                        color: "#677a8e",
                        fontWeight: 500,
                        fontSize: 13,
                        marginBottom: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {CustomerEmail}
                      <span
                        className="check-mark"
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    </p>
                    <p
                      className="input-box-title "
                      style={{
                        // fontWeight: "bold",
                        color: "#677a8e",
                        fontWeight: 500,
                        marginBottom: 10,
                      }}
                    >
                      OTP
                    </p>

                    {inputRefs.map((ref, index) => (
                      <input
                        key={index}
                        ref={ref}
                        maxLength={1}
                        // style={{
                        //   width: "30px",
                        //   height: "30px",
                        //   marginRight: "5px",
                        // }}
                        type="text"
                        minlength="1"
                        maxlength="1"
                        className="form-control otp-inputbar"
                        value={OtpInput[index]}
                        onChange={(e) => handleChange(index, e)}
                      />
                    ))}

                    {OtpErrorFlag && (
                      <p className="otp-error">Please enter a valid OTP</p>
                    )}
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                  <p className="call-again">
                    <span className="underlined">Send again</span>
                  </p>
                </div> */}
                  </div>
                )}
                <div className="p-3 m-1">
                  <p
                    className="input-box-title "
                    style={{
                      // fontWeight: "bold",
                      color: "#677a8e",
                      fontWeight: 500,
                      marginBottom: 10,
                    }}
                  >
                    Email ID
                  </p>
                  <LoginInput
                    Style={{ height: "7vh" }}
                    Type={"email"}
                    Placeholder={"Enter Email"}
                    value={CustomerEmail}
                    onChange={(e) => {
                      setCustomerEmail(e.target.value);
                      setCustomerEmailErrorFlag(false);
                    }}
                  />
                  {CustomerEmailErrorFlag && (
                    <span
                      className="input-box-title "
                      style={{
                        color: "red",
                      }}
                    >
                      Please enter email ID
                    </span>
                  )}
                </div>
                {ShowOTPForm == false && (
                  <>
                    <div className="p-3 m-1">
                      <p
                        className="input-box-title "
                        style={{
                          color: "#677a8e",
                          fontWeight: 500,
                          marginBottom: 10,
                        }}
                      >
                        Password
                      </p>
                      <LoginInput
                        Style={{ height: "7vh" }}
                        Type={"password"}
                        Placeholder={"Password"}
                        value={CustomerPassword}
                        onChange={(e) => {
                          setCustomerPassword(e.target.value);
                          setCustomerPasswordErrorFlag(false);
                        }}
                      />
                      {CustomerPasswordErrorFlag && (
                        <span
                          className="input-box-title "
                          style={{
                            color: "red",
                          }}
                        >
                          Please enter password
                        </span>
                      )}
                    </div>
                  </>
                )}

                {ShowOTPForm == true && (
                  <>
                    <p
                      className="input-box-title "
                      style={{
                        // fontWeight: "bold",
                        color: "#677a8e",
                        fontWeight: 500,
                        marginBottom: 10,
                      }}
                    >
                      OTP
                    </p>

                    {inputRefs.map((ref, index) => (
                      <input
                        key={index}
                        ref={ref}
                        maxLength={1}
                        // style={{
                        //   width: "30px",
                        //   height: "30px",
                        //   marginRight: "5px",
                        // }}
                        type="text"
                        minlength="1"
                        maxlength="1"
                        className="form-control otp-inputbar"
                        value={OtpInput[index]}
                        onChange={(e) => handleChange(index, e)}
                      />
                    ))}
                  </>
                )}

                {changePassword == true && (
                  <>
                    <div>
                      <p
                        className="input-box-title"
                        style={{
                          color: "#677a8e",
                          fontWeight: 500,
                        }}
                      >
                        Enter new password
                      </p>
                      <LoginInput
                        Style={{ height: "7vh" }}
                        Type={"password"}
                        Placeholder={"Enter new password"}
                        Value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <p
                    className="login-forgot-password-text"
                    onClick={() => {
                      setPassLoginFlag(false);
                    }}
                  >
                    Login with OTP?
                  </p>
                  <p
                    className="login-forgot-password-text"
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot Password?
                  </p>
                </div>
                {ShowOTPForm == false && (
                  <button
                    onClick={handlePasswordLogin}
                    className="login-signup-button"
                    type="submit"
                  >
                    <span className="span-1">
                      {TransactionStarted ? <Spinner /> : "Log In"}
                    </span>
                  </button>
                )}
                {ShowOTPForm == true && (
                  <button
                    onClick={verifyOtpHandler}
                    className="login-signup-button"
                    type="submit"
                  >
                    <span className="span-1">
                      {TransactionStarted ? <Spinner /> : "Verify OTP"}
                    </span>
                  </button>
                )}
                {changePassword == true && (
                  <button
                    onClick={changePasswordHandler}
                    className="login-signup-button"
                  >
                    <span className="span-1">
                      {TransactionStarted ? <Spinner /> : "Change password"}
                    </span>
                  </button>
                )}
              </form>
            )}
          </div>
        </Card>
        <div className="login-right-container cover-img">
          {/* <div
          style={{
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <img
            src={LoginBG}
            style={{
              right: 0,
              top: 0,
              // width: 700,
              height: "100vh",
              objectFit: "contain",
              // borderRadius:200
            }}
          />
        </div> */}
        </div>
      </div>
      <div
    
        className="privacy-policy-container"
      >
        <p
          style={{
            textTransform: "none",
            fontSize: 13,
            textAlign: "center",
            bottom: 50,
          }}
        >
          © BetaCode | Privacy Policy | Terms of Service
        </p>
      </div>
    </div>
  );
};

export default Login;
