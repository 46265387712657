import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "daterangepicker";
import moment from "moment";
import "./DateRangePicker.css";

const DateRangePicker = ({ onSelectDateRange }) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleDateRangeSelection = (start, end, label) => {
      const startDate = start.format("YYYY-MM-DD");
      const endDate = end.format("YYYY-MM-DD");
      onSelectDateRange(startDate, endDate);
    };

    const datePicker = $(datePickerRef.current);
    datePicker.daterangepicker(
      {
        opens: "right",
        locale: {
          format: "YYYY-MM-DD",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          customRangeLabel: "Custom Range",
        },
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
      },
      handleDateRangeSelection
    );

    return () => {
      datePicker.off();
      datePicker.data("daterangepicker").remove();
    };
  }, [onSelectDateRange]);

  return (
    <div className="group" style={{ backgroundColor: "#fdfdfe" }}>
      <input type="text" className="text-box-style" ref={datePickerRef} />
    </div>
  );
};

export default DateRangePicker;
