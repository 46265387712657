import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Images from "../assets/images/Images";
import Icons from "../constants/Icons";
// import * as microsoftTeams from "@microsoft/teams-js";
import { connect, useDispatch } from "react-redux";
import { COLORS } from "../constants/theme";
import Page404 from "./Page404";
import Lottie from "react-lottie-player";
import loadingAnime from "../assets/lottieFiles/loader.json";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import welcomeGreetings from "../assets/images/welcome-greeting.svg";
import Navbar from "../components/Navbar/Navbar";

function Dashboard(props) {
  const navigate = useNavigate();
  const [viewAccess, setViewAccess] = useState(false);
  const [empExist, setEmpExist] = useState("NODATA");
  const [role, setRole] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    props
      .EmployeeDetailsGetAction()
      .then((res) => {
      })
      .catch((err) => {
        setEmpExist("ECONNREF");
      });
  }, []);

  useEffect(() => {
    if (props.employee_data.length > 0) {
      if (
        props.employee_data.some((val) => val.email_id == props.auth_email_id)
      ) {
        setEmpExist(true);
        let empRole = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        setRole(empRole);
      } else {
        setEmpExist(false);
      }
    }
  }, [props.employee_data]);


  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        if (role == "view") {
          setViewAccess(true);
        }
      }
    }
  }, [props.employee_data]);

  const textStyle = {
    fontSize: 22,
    fontWeight: "bold",
    color: COLORS.gray70,
    mt: 2,
  };

  const [userDetails, setUserDetails] = useState(null);

  // useEffect(() => {
  //   microsoftTeams.app.initialize().then(() => {
  //     microsoftTeams.authentication.getAuthToken({
  //       successCallback: (token) => {
  //         console.log("Token retrieved:", token);

  //         getUserDetails(token);
  //       },
  //       failureCallback: (error) => {
  //         console.error("Error getting token:", error);
  //         setError(error);
  //       },
  //     });
  //   });
  // }, []);
  const getUserDetails = async (token) => {
    // Decode JWT to extract the payload
    const base64Url = token.split(".")[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Normalize base64 string
    const payload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const decodedToken = JSON.parse(payload);

    // Extract 'oid' from the decoded token
    const oid = decodedToken.oid;
    const upn = decodedToken.upn;
    console.log("auth_token:", oid, upn);
    // console.log('UPN:', );
    // Instead of fetching user details, directly set OID and token in state
    setUserDetails({
      OBJECT_ID: oid,
      token: token, // Store the token directly
      EMAIL_ID: upn,
    });
  };
  const SessionLogout = () => {
    window.localStorage.removeItem("CMS_STOKEN");
    dispatch({
      type: "AUTH_DATA",
      auth_name: "",
      auth_email_id: "",
      user_token: "",
    });
    navigate("/");
  };

  const [authName, setAuthName] = useState("");
  useEffect(() => {
    setAuthName(props.auth_name);
  }, [props.auth_name]);

  return (
    <Box className="dashboard-main-container contact-main">
      <Navbar headText="Dashboard" />
      <Box
        style={{
          marginTop: 50,
        }}
      />

      {/* <Box className="dashboard-header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="logo-box">
            <img src={BetaCodeLogo} alt="" className="dashboard-inorbit-logo" />
          </Box>

          <p className="dashboard-main-container-header"> ContactHub</p>
        </div>

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <PowerSettingsNewIcon
            style={{
              color: "white",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              SessionLogout();
            }}
          />
        </Box>
      </Box> */}

      {/* <h1>{JSON.stringify(userDetails)}</h1> */}
      {empExist == false && <Page404 />}
      {empExist == "ECONNREF" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h1
            style={{
              color: "#205aa3",
            }}
          >
            Server Error, Please try again after some time
          </h1>
        </Box>
      )}
      {empExist == "NODATA" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Lottie
            // className="loading-lottie"
            style={{
              width: 250,
              height: 250,
            }}
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      )}

      {empExist == true && (
        <Box
        //
        >
          <p
            style={{
              fontSize: 18,
              marginLeft: 15,
              fontWeight: "bold",
            }}
          >
            Welcome {authName} !
          </p>
          <img
            src={welcomeGreetings}
            style={{
              width: 200,
              height: 150,
            }}
          />

          <Box
            className={!viewAccess ? "pages-box" : "view-pages-box"}
            //
          >
            {!viewAccess && (
              <Box
                className="page-card"
                onClick={() => {
                  navigate("/contactCapture");
                }}
              >
                <Box className="icon-box">
                  <img
                    style={{ height: 40, width: 45 }}
                    src={Icons.business_card}
                    alt=""
                    className="img-responsive"
                  />
                </Box>
                <Box sx={{ width: "70%" }}>
                  <Typography className="card-text" sx={{ ...textStyle }}>
                    Capture Contact
                  </Typography>
                  <Typography
                    sx={{ color: COLORS.gray30, fontStyle: "italic", mr: 2 }}
                  >
                    Scan business card and fill contact capture form
                  </Typography>
                </Box>
              </Box>
            )}

            <Box
              className="page-card"
      
              onClick={() => {
                navigate("/searchPage");
              }}
            >
              <Box className="icon-box">
                <img
                  style={{ height: 37, width: 40 }}
                  src={Icons.searchColor}
                  alt=""
                  className="img-responsive"
                />
                {/* <IconContext.Provider
              value={{
                color: "#fff",
                size: "21px",
              }}
            >
              <MdPlayArrow />
            </IconContext.Provider> */}
              </Box>
              <Box sx={{ width: "70%" }}>
                <Typography className="card-text" sx={{ ...textStyle }}>
                  Search Contact
                </Typography>
                <Typography
                  sx={{ color: COLORS.gray30, fontStyle: "italic", mr: 2 }}
                >
                  Search by contact name or company name
                </Typography>
              </Box>
            </Box>

            {!viewAccess && (
              <Box
                className="page-card"
                // sx={{
                //   ...cardStyle,
                //   // backgroundColor: "#fdfff0",
                // }}
                onClick={() => {
                  navigate("/sendEmail");
                }}
              >
                <Box className="icon-box">
                  <img
                    style={{ height: 35, width: 40 }}
                    src={Icons.campaign}
                    alt=""
                    className="img-responsive"
                  />
                </Box>
                <Box sx={{ width: "70%" }}>
                  <Typography className="card-text" sx={{ ...textStyle }}>
                    Create Campaign{" "}
                  </Typography>
                  <Typography
                    sx={{ color: COLORS.gray30, fontStyle: "italic", mr: 2 }}
                  >
                    Send email and Whatsapp messages to contacts
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
});

export default connect(mapStateToProps, { EmployeeDetailsGetAction })(
  Dashboard
);
