import React, { useState, useEffect } from "react";
import Tesseract from "tesseract.js";

const ScanText = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [recognizedText, setRecognizedText] = useState("");
  useEffect(() => {
    const recognizeText = async () => {
      if (selectedImage) {
        const result = await Tesseract.recognize(selectedImage);
        setRecognizedText(result.data.text);
      }
    };
    recognizeText();
  }, [selectedImage]);
  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    setSelectedImage(URL.createObjectURL(image));
  };
  const ImageUploader = () => {
    return (
      <div>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {selectedImage && <img src={selectedImage} alt="Selected" />}
      </div>
    );
  };
  const TextRecognition = ({ selectedImage }) => {
    return (
      <div>
        <h2>Recognized Text:</h2>
        <p>{recognizedText}</p>
      </div>
    );
  };
  return (
    <>
      <ImageUploader />
      <TextRecognition selectedImage={selectedImage} />
    </>
  );
};
export default ScanText;
