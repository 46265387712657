import { Avatar, Box, Card, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { deepOrange } from "@mui/material/colors";
import Navbar from "../components/Navbar/Navbar";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import { connect } from "react-redux";

const EmployeeHierarchy = (props) => {
  const [employees, setEmployees] = useState([]);
  const [PyramidTopId, setPyramidTopId] = useState({});

  // Create a map for employees by managerId
  const employeeMap = React.useMemo(() => {
    const map = new Map();
    props.employee_data?.map((employee) => {
      if (employee._id != employee.reportingManager) {
        if (!map.has(employee.reportingManager)) {
          map.set(employee.reportingManager, []);
        }
        map.get(employee.reportingManager).push(employee);
      }

      return null; // map() requires a return statement, even if it's unused
    });

    return map;
  }, [props.employee_data]);

  useEffect(() => {
    if (props.employee_data) {
      const ceo = props.employee_data.find((val) => val?.CEO === true);
      if (ceo) {
        setPyramidTopId(ceo);
      }
      setEmployees(props.employee_data);
    }
  }, [props.employee_data]);

  const navigate = useNavigate();

  const buildTree = (managerId, visited = new Set()) => {
    // Avoid infinite loops by checking for circular references
    if (visited.has(managerId)) {
      console.warn(`Circular reference detected for manager: ${managerId}`);
      return null;
    }
    visited.add(managerId);

    // Get the employees reporting to this manager
    const reports = employeeMap.get(managerId) || [];

    return reports.map((employee) => (
      <TreeNode
        key={employee._id}
        label={
          <div>
            <Card
              sx={{
                p: 1,
                minHeight: 70,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar sx={{ bgcolor: deepOrange[500] }}>
                    {employee.employee_name.charAt(0)}
                  </Avatar>
                </div>
                <span
                  style={{
                    textAlign: "center",
                  }}
                >
                  {employee.employee_name}
                </span>
                <br />
                {employee.designationName && <Divider />}
                <span
                  style={{
                    textAlign: "center",
                    color: "#1770E0",
                    marginTop: 5,
                    fontSize: 14,
                  }}
                >
                  {employee.designationName}
                </span>
              </div>
            </Card>
          </div>
        }
      >
        {buildTree(employee._id, visited)}{" "}
        {/* Recursively add employees who report to this employee */}
      </TreeNode>
    ));
  };

  return (
    <div className="contact-details-container">
      <div>
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
          gutter={24}
          toastOptions={{
            style: {
              border: "1px solid #grey",
              padding: "16px",
            },
            success: {
              style: {
                background: "#c4fcb1",
                opacity: 0.1,
              },
            },
          }}
        />
      </div>
      <Navbar headText="EMPLOYEE_HIERARCHY" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
          ml: 2,
          pt: 2,
          pb: 2,
        }}
      >
        <Box
          sx={{
            mr: 1,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <IconContext.Provider value={{ size: 25, color: "#000" }}>
            <FaArrowLeftLong />
          </IconContext.Provider>
        </Box>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: 600,
            color: "#000",
            marginRight: 10,
            textAlign: "start",
          }}
        >
          Employees Hierarchy
        </Typography>
      </Box>

      <Tree label={<div>{PyramidTopId.employee_name}</div>}>
        {buildTree(PyramidTopId?._id)} {/* Starting from the CEO (ID: "1") */}
      </Tree>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  meeting_data: state.contactData.meetingDataList,
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  auth_id: state.contactData.auth_id,
  auth_name: state.contactData.auth_name,
});

export default connect(mapStateToProps, {})(EmployeeHierarchy);
